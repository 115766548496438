import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

const ProfileSidemenu = () => {
  return (
    <div className="order-menu">
      <ul>
        <li>
          <Link to="/my-orders">
            <span className="iconify">
              <Icon icon="lets-icons:order" />
            </span>
            My Orders
          </Link>
        </li>
        <li>
          <Link to="/shipping-address">
            <span className="iconify">
              <Icon icon="ph:address-book-light" />
            </span>
            My Addresses
          </Link>
        </li>
        <li>
          <Link to="/my-favourite">
            <span className="iconify">
              <Icon icon="mdi:favourite-border" />
            </span>
            My Favourites
          </Link>
        </li>

        <li>
          <Link to="/my-profile">
            <span className="iconify">
              <Icon icon="iconamoon:profile" />
            </span>
            My Profile
          </Link>
        </li>

        {/* <li><Link><span className="iconify">
		  <Icon icon="carbon:location" />
		</span>Track Orders</Link>
	  </li> */}

        {/* <li><Link><span className="iconify">
		  <Icon icon="mdi:password-outline" />
		</span>Change Password</Link>
	  </li> */}
        {/* <li><Link><span className="iconify">
		  <Icon icon="tabler:logout" />
		</span>Sign Out</Link>
	  </li> */}
      </ul>
    </div>
  );
};

export default ProfileSidemenu;
