/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState, Suspense } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import { getCartCount, getCartInsert, getProductList, getTodaySpecial, postCartUpdate, userValidate } from "../Services/serviceApi";
//import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import InputGroup from "react-bootstrap/InputGroup";
import { useCookies } from 'react-cookie';
import SkeletonLoader from "../Components/skeleton-loader";
import ToastMessage from "../Components/toast-message";
import { useParams } from "react-router-dom";

const ProductList = () => {

  const { state } = useLocation();
  const { id } = useParams();
  const data = (state != null) ? state : '';
  const catId = (id != '' && id != undefined) ? id : data;
  const [products, setProducts] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [specialProducts, setSpecialProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [cookies] = useCookies(['SESSIONINFO'], ['LOGGEDINFO']);
  const userId = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? cookies.LOGGEDINFO : cookies.SESSIONINFO;
  const userType = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? '' : 'guest';
  const [show, setShow] = useState(false);
  const [cartMsg, setCartMsg] = useState('');
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [totalCount, setTotalCount] = useState('');
  const [emptyData, setEmptyData] = useState({ status: 1 });
  const [previousStateData, setPreviousStateData] = useState('');
  const [userData, setUserData] = useState('');

  useEffect(() => {
    // setPage(page+1)
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading, catId, page]);

  useEffect(() => {
    setProducts([]);
    fetchData(1);
  }, [state]);

  const fetchData = async (page) => {
    setIsLoading(true);
      setPage(1);
    setPreviousStateData(catId)
    setTimeout(async () => {
      getProductList(catId, userId, userType, page, '')
        .then(response => {
          if (response.status === 1 && response.products.length > 0) {
            // setIsFetching(false);
            setTotalCount(response.productCount[0].productcount)
            setPage(page + 1)
            setTotalPage(response.total_page)
            setEmptyData(response);
            setIsLoading(false)
            setProducts(response.products);
          }
          if (response.status === 2) {
            // setIsFetching(false);
            setEmptyData(response);
            setIsLoading(false)
          }
        }, 500)
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const handleScroll = () => {
    const distanceToBottom = document.body.offsetHeight - (window.innerHeight + window.scrollY);
    const loadThreshold =270; 
    if (distanceToBottom < loadThreshold && !isFetching && page<=totalPage) {
        // fetchMoreData();
        setIsFetching(true)
    }
  };

  useEffect(() => {
    if (isFetching) {
      fetchMoreData();
    }
  }, [isFetching]);

  
  const fetchMoreData = async () => {
    setIsFetching(true)
    // setTimeout(async () => {
      if (emptyData.status === 1) {
        getProductList(catId, userId, userType, page, '')
          .then(response => {
            if (response.status === 1 && response.products.length > 0) {
              setEmptyData(response);
              setProducts(() => {
                return [...products, ...response.products];
              });
              setPage(page+1)
              setIsFetching(false)
            }
            if (response.status === 2) {
              setEmptyData(response);
              setIsLoading(false)
              setIsFetching(false)
            }
            setIsFetching(false)
          })
      }
    // }, 500);
  };

  const onSortProducts = (e) => {
    if (e.target.value != '') {
      getProductList(catId, userId, userType, page, e.target.value).then(response => {
        if (response.products != undefined && response.products.length > 0) {
          setProducts(response.products);
        }
      })
    }
  }

  const getSplProducts = () => {
    getTodaySpecial(userId, userType, '1', '').then(response => {
      specialProducts.map((product) => (
        product.product_id
      ))
      setSpecialProducts(response.todayspecial)
    })
  }

  useEffect(() => {
    getSplProducts();
    updateQuantityChange()
  }, [])

  const addToCart = (product) => {
    getCartInsert(userType, userId, product.product_id)
      .then(response => {
        setUserData(response)
        setCartMsg(response.data.message)
        setShow(true)
        updateQuantityChange(product.product_id, '');
      })
  };

  const updateQuantityChange = (productId, action) => {
    const updatedProducts = products.map((product) => {
      if (product.product_id === productId) {
        let currentQuantity = parseInt(product.cart_qty) || 0;
        let updatedQuantity;
        if (action === 'increment') {
          updatedQuantity = currentQuantity + 1;
          setShow(true)
          setCartMsg('Success! Quantity added to your cart. Keep on shopping!')
        } else if (action === 'decrement' && currentQuantity > 0) {
          updatedQuantity = currentQuantity - 1;
          if (updatedQuantity == 0) {
            setShow(true)
            setCartMsg('Product successfully removed from the cart.')
          } else {
            setShow(true)
            setCartMsg('Quantity reduced successfully. Your cart is updated.')
          }
        } else if (action == '') {
          updatedQuantity = 1;
          setShow(true)
        } else {
          return product;
        }
        postCartUpdate(userId, productId, updatedQuantity, userType)
          .then(response => {
            setUserData(response)
            setCartCount(response)
          })
        return {
          ...product,
          cart_qty: updatedQuantity
        };
      }
      return product;
    });

    setProducts(updatedProducts);
  };

  const getCartCounts = () => {
    getCartCount(userId, userType)
      .then(response => {
        setCartCount(response)
      })
  }

  // useEffect(() => {
  //   getCartCounts()
  // }, [])

  return (
    <div>
      <Header cartCount={cartCount} userData={userData} />
      <section className="product-detail mt-space pt-space">

        <div className="product-item">
          <Container>
            <div className="breadcrumb">
              <ul>
                <li>
                  <Link to='/'>
                    Home
                    <span className="iconify">
                      <Icon icon="iconamoon:arrow-right-2-duotone" />
                    </span>
                  </Link>
                </li>
                <li className="active">{catId.charAt(0).toUpperCase() + catId.slice(1)}</li>
              </ul>
            </div>
            {(!isLoading && products.length === 0) ?
              <section className="pb-sapce pt-space">
                <div className="no-record text-center para-text">
                  <img
                    className="zoom-effect"
                    src="../assets/images/no-record.svg"
                    alt="Cart Image" title="Empty Cart"
                  />
                  <div className="empty-text">Sorry, no products available at the moment. Please check back later</div>
                </div>
              </section> :
              <Row className="d-flex align-items-center">
                <Col lg={6} md={6} xs={5}>
                  <div className="item-list">
                    <p>{totalCount} Products Found</p>
                  </div>
                </Col>
                {/* <Col lg={6} md={6} xs={7}>
                  <div className="price-list text-end">
                    <div className="price-option">
                      <span>Sort by:</span>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => { onSortProducts(e) }}
                      >
                        <option value="default">Default</option>
                        <option value="lowest_price">Lowest Price</option>
                        <option value="highest_price">Highest Price</option>
                        <option value="today_special">Today's special</option>
                      </Form.Select>
                    </div>
                  </div>
                </Col> */}
              </Row>
            }
          </Container>
        </div>

        {(isLoading) ? <SkeletonLoader /> : <div className="product-list">
          <Container>
            <Row>
              {products.map((product, index) => (
                <Suspense key={index}
                  fallback={
                    <img src={''} alt="Avatar" style={{ width: "50%" }} />
                  }
                >
                  <Col lg={3} md={4} xs={6} key={index}>
                    <div className="product-box detail-box">
                      <Link to={`/product-detail/${product.product_slug}`} state={{ product }}>
                        <div className="product-img">
                          <img
                            className="zoom-effect"
                            src={product.product_image}
                            alt={product.product_name}
                          />
                          <div className="product-icon">
                            {product.today_special === "1" ? (
                              <img src="../assets/images/special-icon.svg" alt="" />
                            ) : ('')}
                          </div>

                          {parseInt(product.selling_price)!== 0 && product.selling_price != null ?
                            (<div className="product-offer-box offer-orange">
                              <span>
                                <strong>{Math.round((product.actual_price - product.selling_price) / product.actual_price * 100)}%</strong> <br />
                                <span className="off-text">OFF</span>
                              </span>
                            </div>) : ('')}
                        </div>
                        <div className="product-title">
                          <h4>{product.product_name.charAt(0).toUpperCase() + product.product_name.slice(1)}  / {product.product_name2} - {product.variant_name + ' ' + product.nic_name} </h4>
                        </div>
                      </Link>
                      <div className="select-card">
                        <Row className="d-flex align-items-center">
                          <Col lg={6} md={6} xs={12}>
                            <div className="price-list">
                              <h5>
                                {<span className="price-tag">₹{(parseInt(product.selling_price)!== 0 && product.selling_price != null) ? product.selling_price : product.actual_price}</span>}
                                {(parseInt(product.selling_price)!== 0 && product.selling_price != null) && <><span className="price-low">₹{product.actual_price}</span> <br /> You
                                  save ₹ {product.actual_price - product.selling_price}</>}
                              </h5>
                            </div>
                          </Col>
                          <Col lg={6} md={6} xs={12}>
                            {product.cart_qty === null || product.cart_qty === 0 ? (
                              <div className="cart-btn">
                                {product.stock_availability === '0' ? (
                                  <p className="out_of_stock">Out of Stock</p>) : (

                                  <Link to="javascript:;" onClick={() => addToCart(product)}>
                                    <span className="iconify">
                                      <Icon icon="solar:cart-4-linear" />
                                    </span>
                                    <span className="cart-hide">Add to Cart</span>
                                  </Link>
                                )}
                              </div>) :
                              (
                                <div className="price-add">
                                  {((product.stock_availability == 0) ?
                                    <p className="out_of_stock">Out of Stock</p> :
                                    (product.cart_qty !== null || product.cart_qty !== 0) ? (
                                      <div className="quantity-price mt-0">
                                        <Button className="qty-button" onClick={() => updateQuantityChange(product.product_id, 'decrement')}>
                                          <span className="iconify">
                                            <Icon icon="ic:baseline-minus" />
                                          </span>
                                        </Button>
                                        <InputGroup className="qty-count">
                                          <Form.Control
                                            placeholder="1"
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            value={product.cart_qty}
                                            onChange={(e) => updateQuantityChange(product.product_id, e.target.value)}

                                          />
                                        </InputGroup>
                                        <Button className="qty-button" onClick={() => updateQuantityChange(product.product_id, 'increment')}>
                                          <span className="iconify">
                                            <Icon icon="ic:baseline-plus" />
                                          </span>
                                        </Button>
                                      </div>
                                    ) : ('')
                                  )}
                                </div>

                              )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Suspense>
              ))}
            </Row>
          </Container>
          {/* {(emptyData.products != undefined && emptyData.products.length == 4) ? <div className="view-btn">
            <Link  className="">
              VIEW More
            </Link>
          </div> : ''} */}
        </div>}
      </section>
      <ToastMessage show={show} setShow={setShow} cartMsg={cartMsg} />
      <Footer />
    </div>
  );
};

export default ProductList;
