import React,{useState,useEffect} from 'react';
import Button from "react-bootstrap/Button";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ModalWindow = ({data ,onCloseModel}) => {
    const {title, message, type} = data || {}
    const [isModalVisible,setModalVisible] = useState(data.show);
    useEffect(() => {
      setModalVisible(data.show);
    },[data]);
  
    const closeModal = (e,type) => {
      e.preventDefault();
      if(type!='backdropClick')
        setModalVisible(false);
      if(onCloseModel !=undefined && onCloseModel !='' )
        onCloseModel(type);
    }
    
    return <Modal show={isModalVisible} centered className="alertModals">
        <div className="popup-content">
          <Row>            
            <Col lg={12} md={12} xs={12}>
              <div className="location-modal">              
                {/* <Button className="closeModal" onClick={closeModal}><Icon icon="ion:close-outline" /></Button> */}
                <Modal.Body>
                    <h5>{title}</h5>
                    <p>{message}</p>
                    <div className="alert-box">
                    {(type=='success' || type=='error') && <Button className="contained btn-big yellow-btn" onClick={e=>closeModal(e,1)}>Ok</Button>}
                      {(type =='confirmation') && <><Button variant="light" onClick={e=>closeModal(e,0)}>No</Button>
                      <Button variant="primary" onClick={e=>closeModal(e,1)}>Yes</Button></>}
                    </div>
                </Modal.Body>
              </div>
            </Col>
          </Row>
        </div>
    </Modal>
};

export default ModalWindow;