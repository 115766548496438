import React from "react";
import Toast from "react-bootstrap/Toast";
import { Icon } from "@iconify/react";

const ToastMessage = ({show,setShow,cartMsg}) => {

    return(<div className="toast-box">
       <Toast
         onClose={() => setShow(false)}
         show={show}
         delay={3000}
         autohide
       >
         <Toast.Header>
           <span className="iconify">
             <Icon icon="ep:success-filled" />
           </span>
           {cartMsg}
         </Toast.Header>
       </Toast>
     </div>
    );
};

export default ToastMessage;