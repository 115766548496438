import React from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

const SkeletonLoader = ({loadertype}) => {

    const productListLoader = () =>{
        return <Container>
        <Row>
        <Col lg={3} md={4} xs={6}>
        <div className="product-box detail-box"><Link to="/ProductDetail"><div className="product-img skeleton-loader"><div className="product-icon skeleton-loader"></div><div className="product-offer-box offer-orange skeleton-loader"></div></div><div className="product-title skeleton-loader"></div></Link><div className="select-card"><Row className="d-flex align-items-center"><Col lg={6} md={6} xs={12}><div className="price-list skeleton-loader"></div></Col><Col lg={6} md={6} xs={12}><div className="cart-btn skeleton-loader"></div></Col>
        </Row>
        </div></div>
        </Col>
        <Col lg={3} md={4} xs={6}>
        <div className="product-box detail-box"><Link to="/ProductDetail"><div className="product-img skeleton-loader"><div className="product-icon skeleton-loader"></div><div className="product-offer-box offer-orange skeleton-loader"></div></div><div className="product-title skeleton-loader"></div></Link><div className="select-card"><Row className="d-flex align-items-center"><Col lg={6} md={6} xs={12}><div className="price-list skeleton-loader"></div></Col><Col lg={6} md={6} xs={12}><div className="cart-btn skeleton-loader"></div></Col>
        </Row>
        </div></div>
        </Col>
        <Col lg={3} md={4} xs={6}>
        <div className="product-box detail-box"><Link to="/ProductDetail"><div className="product-img skeleton-loader"><div className="product-icon skeleton-loader"></div><div className="product-offer-box offer-orange skeleton-loader"></div></div><div className="product-title skeleton-loader"></div></Link><div className="select-card"><Row className="d-flex align-items-center"><Col lg={6} md={6} xs={12}><div className="price-list skeleton-loader"></div></Col><Col lg={6} md={6} xs={12}><div className="cart-btn skeleton-loader"></div></Col>
        </Row>
        </div></div>
        </Col>
        <Col lg={3} md={4} xs={6}>
        <div className="product-box detail-box"><Link to="/ProductDetail"><div className="product-img skeleton-loader"><div className="product-icon skeleton-loader"></div><div className="product-offer-box offer-orange skeleton-loader"></div></div><div className="product-title skeleton-loader"></div></Link><div className="select-card"><Row className="d-flex align-items-center"><Col lg={6} md={6} xs={12}><div className="price-list skeleton-loader"></div></Col><Col lg={6} md={6} xs={12}><div className="cart-btn skeleton-loader"></div></Col>
        </Row>
        </div></div>
        </Col>
        </Row>
        </Container>
    }

    const searchLoader = () =>{
        return <><div className="auto-list">
            <ul><li><div className="d-flex">
                <div className="left-search skeleton-loader"></div>
                <div className="right-search skeleton-loader"></div>
            </div></li></ul>
        </div>
            <div className="auto-list">
            <ul><li><div className="d-flex">
                <div className="left-search skeleton-loader"></div>
                <div className="right-search skeleton-loader"></div>
            </div></li></ul>
            </div>
            <div className="auto-list">
            <ul><li><div className="d-flex">
                <div className="left-search skeleton-loader"></div>
                <div className="right-search skeleton-loader"></div>
            </div></li></ul>
            </div>
            <div className="auto-list">
            <ul><li><div className="d-flex">
                <div className="left-search skeleton-loader"></div>
                <div className="right-search skeleton-loader"></div>
            </div></li></ul>
            </div>
            <div className="auto-list">
            <ul><li><div className="d-flex">
                <div className="left-search skeleton-loader"></div>
                <div className="right-search skeleton-loader"></div>
            </div></li></ul></div></>
    };

    return(
        (loadertype === 'search')?searchLoader():productListLoader()
    );
};

export default SkeletonLoader;