import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import Badge from "react-bootstrap/Badge";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const Order = () => {
  return (
    <div>
      <Header />
      <section className="cart-section mt-space pt-space">
        <Container>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link>
                  Home
                  <span className="iconify">
                    <Icon icon="iconamoon:arrow-right-2-duotone" />
                  </span>
                </Link>
              </li>
              <li>
                <Link>
                  Products
                  <span className="iconify">
                    <Icon icon="iconamoon:arrow-right-2-duotone" />
                  </span>
                </Link>
              </li>
              <li>
                <Link>
                  Product Detail
                  <span className="iconify">
                    <Icon icon="iconamoon:arrow-right-2-duotone" />
                  </span>
                </Link>
              </li>
              <li>
                <Link>
                  Cart
                  <span className="iconify">
                    <Icon icon="iconamoon:arrow-right-2-duotone" />
                  </span>
                </Link>
              </li>
              <li className="active">Checkout</li>
            </ul>
          </div>

          <Row>
            <Col lg={9} md={4} xs={12}>
              <div className="cart-title">
                <h4>Delivery Address</h4>
              </div>
              <div className="cart-body">
                <Row>
                  <Col lg={6} md={4} xs={12}>
                    <div className="address-box">
                      <div className="address-type">
                        <span>Home</span>
                      </div>
                      <div className="address-header">
                        <Form.Check type="radio" aria-label="radio 1" />
                        <h5>Jack Jennas</h5>
                      </div>
                      <p>
                        <span>Address :</span>8424 James Lane South
                        SanFrancisco, CA 94080
                      </p>
                      <p>
                        <span>Pin Code:</span>60003
                      </p>
                      <p>
                        <span>Phone:</span>+91 698 354 3591
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} md={4} xs={12}>
                    <div className="address-box">
                      <div className="address-type">
                        <span>Office</span>
                      </div>
                      <div className="address-header">
                        <Form.Check type="radio" aria-label="radio 1" />
                        <h5>Jack Jennas</h5>
                      </div>
                      <p>
                        <span>Address :</span>8424 James Lane South
                        SanFrancisco, CA 94080
                      </p>
                      <p>
                        <span>Pin Code:</span>60003
                      </p>
                      <p>
                        <span>Phone:</span>+91 698 354 3591
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="cart-title">
                <h4>Delivery Option</h4>
              </div>
              <div className="cart-body">
                <Row>
                  <Col lg={12} md={4} xs={12}>
                    <div className="accordion-box">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header> Cash On Delivery</Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            Credit or Debit Card
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="payment-method">
                              <Row>
                                <Col lg={12} md={4} xs={12}>
                                  <div className="">
                                    <InputGroup className="mb-3 payment-input">
                                      <Form.Control
                                        placeholder="Enter Credit & Debit Card Number"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                      />
                                    </InputGroup>
                                  </div>
                                </Col>
                                <Col lg={4} md={4} xs={12}>
                                  <div className="">
                                    <InputGroup className="mb-3 payment-input">
                                      <Form.Control
                                        placeholder="Enter Expiry Date"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                      />
                                    </InputGroup>
                                  </div>
                                </Col>
                                <Col lg={4} md={4} xs={12}>
                                  <div className="">
                                    <InputGroup className="mb-3 payment-input">
                                      <Form.Control
                                        placeholder="Enter CVV Number"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                      />
                                    </InputGroup>
                                  </div>
                                </Col>
                                <Col lg={4} md={4} xs={12}>
                                  <div className="">
                                    <InputGroup className="mb-3 payment-input">
                                      <Form.Control
                                        placeholder="Password"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                      />
                                    </InputGroup>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Net Banking</Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={3} md={4} xs={12}>
              <div className="cart-title">
                <h4>Order Summary</h4>
              </div>
              <div className="cart-left">
                <div className="coupan-content">
                  <ul>
                    <li>
                      <div className="product-cart-list">
                        
                        <img
                          className="order-image"
                          src="../assets/images/product-detail.png"
                          alt=""
                          title=""
                        />
                        <span>Onion</span>
                      </div>
                      <h4>₹41</h4>
                    </li>
                    <li>
                      <div className="product-cart-list">
                        
                        <img
                          className="order-image"
                          src="../assets/images/product-detail.png"
                          alt=""
                          title=""
                        />
                        <span>Onion</span>
                      </div>
                      <h4>₹41</h4>
                    </li>
                    <li>
                      <div className="product-cart-list">
                        <img
                          className="order-image"
                          src="../assets/images/product-detail.png"
                          alt=""
                          title=""
                        />
                        <span>Onion</span>
                      </div>
                      <h4>₹41</h4>
                    </li>
                  </ul>
                </div>
                <div className="coupan-content">
                  <ul>
                    <li>
                      <h4>Subtotal</h4>
                      <h4>₹123</h4>
                    </li>
                    <li>
                      <h4>Shipping</h4>
                      <h4>₹10</h4>
                    </li>
                    <li>
                      <h4 className="text-color">Coupon/Code</h4>
                      <h4 className="text-color">htrg84</h4>
                    </li>
                  </ul>
                </div>
                <div className="summary-total">
                  <ul>
                    <li>
                      <h4>Total</h4>
                      <h4 className="text-color">₹133</h4>
                    </li>
                  </ul>
                </div>
                <div className="cart-button">
                  <Link to="/Checkout">Place on Order</Link>
                </div>
                {/* <div className="return-button">
                  <Link to="/product-list"> <span className="iconify">
                              <Icon icon="ion:arrow-back-outline" />
                           </span>Return to Shopping</Link>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default Order;
