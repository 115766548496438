/* eslint-disable no-lone-blocks */
import React, { useEffect, useState, Suspense } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import { getCartCount, getCartInsert, getCategoryList, getProductList, getSearchproduct, getTodaySpecial, postCartUpdate } from "../Services/serviceApi";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useCookies } from 'react-cookie';
// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import InputGroup from "react-bootstrap/InputGroup";
import ToastMessage from "../Components/toast-message";

const Search = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const data = (state != null) ? state.searchKey : '';
  const searchKey = (id != '' && id != undefined) ? id : data;
  const [cookies] = useCookies(['SESSIONINFO'], ['LOGGEDINFO']);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [cartCount, setCartCount] = useState(0);
  const [categories, setCategories] = useState([]);
  const [specialProducts, setSpecialProducts] = useState([]);
  /* const [searchResults, setSearchResults] = useState([] || undefined); */
  const [productsList, setProductList] = useState([] || undefined);
  const [catId, setCatId] = useState('')
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [totalCount, setTotalCount] = useState('')
  // const [emptyData, setEmptyData] = useState({ status: 1 });
  const [isLoading, setIsLoading] = useState(true)
  const [isfetching, setIsFetching] = useState(false);
  const [previousSearch, setPreviousSearch] = useState('');
  const userId = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? cookies.LOGGEDINFO : cookies.SESSIONINFO;
  const userType = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? '' : 'guest';
  const [show, setShow] = useState(false);
  const [cartMsg, setCartMsg] = useState('');
  const [userData, setUserData] = useState('');


  const getCategoryListData = () => {
    return (categories.map((category) => (
      <li key={category.category_slug}>
        <Form.Check type="radio" aria-label="radio 1" id={category.category_slug}
          checked={selectedCategory === category.category_slug}
          onChange={() => categoryList(category.category_slug)} />
        <Link onClick={() => { categoryList(category.category_slug) }}>
          {category.category_name}
        </Link>
      </li>
    )));
  };

  const categoryList = (data) => {
    setPage(1);
    setSelectedCategory(data)
    setCatId(data);
    fetchData(data, 1);
    localStorage.setItem('searchitem', data)
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading, catId, page]);

  const fetchData = async (catid, page) => {
    setIsLoading(true);
    setPreviousSearch(searchKey)
    getSearchproduct(searchKey, userId, userType, '1', catid, page)
      .then(response => {
        if (response.data.status === 1 && response.data.searchproducts.length > 0) {
          setTotalCount(response.data.searchproducts[0].tot_prod_cnt)
          setTotalPage(response.data.total_page)
          setProductList(response.data.searchproducts);
          setPage(page + 1);
          setIsLoading(false);
          if (catid == '') {
            setCatId('')
          }
        }
        if (response.data.status === 2) {
          setIsLoading(false);
          setProductList([]);
        }
      },)
      // }
      .finally(() => {
        setIsLoading(false);
      });
  };


  const handleScroll = () => {
    const distanceToBottom = document.body.offsetHeight - (window.innerHeight + window.scrollY);
    const loadThreshold = 450;
    if (distanceToBottom < loadThreshold && !isfetching && page <= totalPage) {
      // fetchMoreData(catId, page, '');
      setIsFetching(true)
    }
  };


  useEffect(() => {
    if (isfetching) {
      fetchMoreData(catId, page, '');
    }
  }, [isfetching]);

  const fetchMoreData = async (catid, page) => {
    setPreviousSearch(searchKey)
    // setIsLoading(true)
    // setTimeout(() => {
    setIsFetching(true)
    getSearchproduct(searchKey, userId, userType, '1', catid, page)
      .then(response => {
        if (response.data.status === 1 && response.data.searchproducts.length > 0) {
          setPage(page + 1);
          setProductList(() => {
            return [...productsList, ...response.data.searchproducts];
          });
          setIsFetching(false)
          setIsLoading(false);
          if (catid == '') {
            setCatId('')
          }
        }
        if (response.data.status === 2) {
          setIsLoading(false);
          setProductList([]);
          setIsFetching(false)
        }
        setIsFetching(false)
        setIsLoading(false)
      },)
    // },);
  };


  if (previousSearch != '' && previousSearch !== searchKey) {
    fetchData('', 1);
    localStorage.removeItem('searchitem');
    setSelectedCategory('');
  }

  const onSortProducts = (e) => {
    const sortOption = e.target.value;
    if (sortOption !== '') {
      //fetchData(catId, page, sortOption);
    }
  };

  const getSplProducts = () => {
    getTodaySpecial(userId, userType, '1', '1').then(response => {
      specialProducts.map((product) => (
        product.product_id
      ))
      setSpecialProducts(response.todayspecial)
    })
  }

  useEffect(() => {
    //getProducts()
    getSplProducts();
    updateQuantityChange()
    const data = localStorage.getItem('searchitem');
    if (data != null) {
      fetchData(data, 1)
      setSelectedCategory(data)
    }
    else {
      fetchData('', 1)
      setSelectedCategory('')
    }
  }, [])

  const addToCart = (product) => {
    getCartInsert(userType, userId, product.product_id)
      .then(response => {
        setCartMsg(response.data.message)
        setShow(true)
        updateQuantityChange(product.product_id, '');
        setUserData(response)
      })
  };

  const updateQuantityChange = (productId, action) => {
    //const products = (searchResults.length >0)?searchResults:productsList;
    const updatedProducts = productsList.map((product) => {
      if (product.product_id === productId) {
        let currentQuantity = parseInt(product.cart_qty) || 0;
        let updatedQuantity;
        if (action === 'increment') {
          updatedQuantity = currentQuantity + 1;
          setShow(true)
          setCartMsg('Success! Quantity added to your cart. Keep on shopping!')
        } else if (action === 'decrement' && currentQuantity > 0) {
          updatedQuantity = currentQuantity - 1;
          if (updatedQuantity == 0) {
            setShow(true)
            setCartMsg('Product successfully removed from the cart.')
          } else {
            setShow(true)
            setCartMsg('Quantity reduced successfully. Your cart is updated.')
          }
        } else if (action == '') {
          updatedQuantity = 1;
          setShow(true)
        } else {
          return product;
        }
        postCartUpdate(userId, productId, updatedQuantity, userType)
          .then(response => {
            setUserData(response)
            setCartCount(response)
          })
        return {
          ...product,
          cart_qty: updatedQuantity
        };
      }
      return product;
    });
    setProductList(updatedProducts);
  };

  const getCartCounts = () => {
    getCartCount(userId, userType)
      .then(response => {
        setCartCount(response)
      })
  }

  const getCategories = () => {
    getCategoryList().then(response => {
      setCategories(response.data.data)
    })
  }

  const productList = () => {
    const content = [];
    //const resultdata = (searchResults.length >0)?searchResults:productsList;
    {
      productsList.map((product, index) => {
        content.push(
          <Col lg={4} md={6} xs={6} key={index}>
            <div className="product-box detail-box">
              <Link to={`/product-detail/${product.product_slug}`} state={{ product }}>
                <div className="product-img">
                  <img
                    className="zoom-effect"
                    src={product.product_image}
                    alt={product.product_name}
                  />
                  <div className="product-icon">
                    {product.today_special === "1" ? (
                      <img src="../assets/images/special-icon.svg" alt="" />
                    ) : ('')}
                  </div>
                  {parseInt(product.selling_price) !== 0 && product.selling_price !== null ?
                    (<div className="product-offer-box offer-orange">
                      <span>
                        <strong>{Math.round((product.actual_price - product.selling_price) / product.actual_price * 100)}%</strong> <br />
                        <span className="off-text">OFF</span>
                      </span>
                    </div>) : ('')}
                </div>
                <div className="product-title">
                  <h4>{product.product_name.charAt(0).toUpperCase() + product.product_name.slice(1)}  / {product.product_name2} - {product.variant_name + ' ' + product.nic_name} </h4>
                </div>
              </Link>
              <div className="select-card">
                <Row className="d-flex align-items-center">
                  <Col lg={6} md={6} xs={12}>
                    <div className="price-list">
                      <h5>
                        {<span className="price-tag">₹{(parseInt(product.selling_price) !== 0 && product.selling_price != null) ? product.selling_price : product.actual_price}</span>}
                        {(parseInt(product.selling_price) !== 0 && product.selling_price != null) && <><span className="price-low">₹{product.actual_price}</span> <br /> You
                          save ₹ {product.actual_price - product.selling_price}</>}
                      </h5>
                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    {product.cart_qty === null || product.cart_qty === 0 ? (
                      <div className="cart-btn">
                        {product.stock_availability === '0' ? (
                          <p className="out_of_stock">Out of Stock</p>) : (

                          <Link to="javascript:;" onClick={() => addToCart(product)}>
                            <span className="iconify">
                              <Icon icon="solar:cart-4-linear" />
                            </span>
                            <span className="cart-hide">Add to Cart</span>
                          </Link>
                        )}
                      </div>) :
                      (
                        <div className="price-add">
                          {((product.stock_availability == '0') ?
                            <p className="out_of_stock">Out of Stock</p> :
                            (product.cart_qty !== null || product.cart_qty !== 0) ? (
                              <div className="quantity-price mt-0">
                                <Button className="qty-button" onClick={() => updateQuantityChange(product.product_id, 'decrement')}>
                                  <span className="iconify">
                                    <Icon icon="ic:baseline-minus" />
                                  </span>
                                </Button>
                                <InputGroup className="qty-count">
                                  <Form.Control
                                    placeholder="1"
                                    aria-label="Username"
                                    value={product.cart_qty}
                                    onChange={(e) => updateQuantityChange(product.product_id, e.target.value)}
                                  />
                                </InputGroup>
                                <Button className="qty-button" onClick={() => updateQuantityChange(product.product_id, 'increment')}>
                                  <span className="iconify">
                                    <Icon icon="ic:baseline-plus" />
                                  </span>
                                </Button>
                              </div>
                            ) : ('')
                          )}
                        </div>

                      )}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        )
      })
    }
    return content;
  }

  useEffect(() => {
    getCategories();
  }, [])

  return (
    <div>
      <Header cartCount={cartCount} userData={userData}/>
      <section className="product-detail mt-space">
        <div className="border-line-top"></div>
        <div className="product-item">
          <Container>
            {(productsList.length > 0) ? <>
              <Row className="d-flex align-items-center">
                <Col lg={6} md={6} xs={5}>
                  <div className="item-list">
                    <p>{totalCount} Products Found</p>
                  </div>
                </Col>
                {/* <Col lg={6} md={6} xs={7}>
                  <div className="price-list text-end">
                    <div className="price-option">
                      <span>Sort by:</span>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => { onSortProducts(e) }}
                      >
                        <option value="default">Default</option>
                        <option value="lowest_price">Lowest Price</option>
                        <option value="highest_price">Highest Price</option>
                        <option value="today_special">Today's special</option>
                      </Form.Select>
                    </div>
                  </div>
                </Col> */}
              </Row></> : ''}

            <div className="category-list ">
              <Row className="d-flex justify-content-center">
                {(productsList.length > 0 || selectedCategory != '') ?
                  <>
                    <div className="item-list shop-bottom">
                      <p>Shop by Category</p>
                      {/* <span className="category-dropdown down-icon"><Icon icon="icon-park:down" /></span> */}
                    </div>
                    <Col lg={3} md={3} xs={12}>
                      <div className="category-type">
                        <div className="category-option">
                          {/* <h4>Vegetables <span className="product-count"></span></h4> */}
                          <div className="category-items">
                            <ul>
                              {getCategoryListData()}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </> : ''}

                <Col lg={9} md={9} xs={12}>
                  {(!isLoading  && productsList.length === 0) ? (
                    <section className="">
                      <div className="no-record text-center para-text">
                        <img
                          className="zoom-effect"
                          src="../assets/images/no-record.svg"
                          alt="Cart Image" title="Empty Cart"
                        />
                        <div className="empty-text">Sorry, no products available at the moment. Please check back later</div>
                      </div>
                    </section>) :
                    (<>
                      {(isLoading) ?
                        <Container>
                          <Row>
                            <Col lg={4} md={4} xs={6}>
                              <div className="product-box detail-box"><Link to="/ProductDetail"><div className="product-img skeleton-loader"><div className="product-icon skeleton-loader"></div><div className="product-offer-box offer-orange skeleton-loader"></div></div><div className="product-title skeleton-loader"></div></Link><div className="select-card"><Row className="d-flex align-items-center"><Col lg={6} md={6} xs={12}><div className="price-list skeleton-loader"></div></Col><Col lg={6} md={6} xs={12}><div className="cart-btn skeleton-loader"></div></Col>
                              </Row>
                              </div></div>
                            </Col>
                            <Col lg={4} md={4} xs={6}>
                              <div className="product-box detail-box"><Link to="/ProductDetail"><div className="product-img skeleton-loader"><div className="product-icon skeleton-loader"></div><div className="product-offer-box offer-orange skeleton-loader"></div></div><div className="product-title skeleton-loader"></div></Link><div className="select-card"><Row className="d-flex align-items-center"><Col lg={6} md={6} xs={12}><div className="price-list skeleton-loader"></div></Col><Col lg={6} md={6} xs={12}><div className="cart-btn skeleton-loader"></div></Col>
                              </Row>
                              </div></div>
                            </Col>
                            <Col lg={4} md={4} xs={6}>
                              <div className="product-box detail-box"><Link to="/ProductDetail"><div className="product-img skeleton-loader"><div className="product-icon skeleton-loader"></div><div className="product-offer-box offer-orange skeleton-loader"></div></div><div className="product-title skeleton-loader"></div></Link><div className="select-card"><Row className="d-flex align-items-center"><Col lg={6} md={6} xs={12}><div className="price-list skeleton-loader"></div></Col><Col lg={6} md={6} xs={12}><div className="cart-btn skeleton-loader"></div></Col>
                              </Row>
                              </div></div>
                            </Col>
                          </Row>
                        </Container> : <Row>
                          {productList()}
                        </Row>
                      }
                    </>)}
                </Col>
              </Row>
            </div>

          </Container>
          <ToastMessage show={show} setShow={setShow} cartMsg={cartMsg} />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Search;
