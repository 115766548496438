import React from "react";
import Container from "react-bootstrap/Container";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ModalWindow from "../Components/ModalWindow";
/* import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion"; */

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ProfileSidemenu from "../Components/ProfileSidemenu";
import { getProfile, postProfileEdit } from "../Services/serviceApi";
import { useCookies } from 'react-cookie';

const MyProfile = () => {

  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('0');
  const [d_o_b, setDob] = useState('');
  const [errors, setErrors] = useState({});
  const [modalData, setModal] = useState({ show: false, type: '', title: '', message: '' })
  const [cookies] = useCookies(['SESSIONINFO'], ['LOGGEDINFO']);
  const userId = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? cookies.LOGGEDINFO : cookies.SESSIONINFO;

  const getUserProfile = () => {
    getProfile(userId).then(response => {
      setName(response.data.message.name)
      setMobile(response.data.message.mobile)
      setEmail(response.data.message.email)
      setGender(response.data.message.gender)
      setDob(response.data.message.d_o_b)
    })
  }

  useEffect(() => {
    getUserProfile()
  }, [])

  const validateMobile = (mobile) => {
    return /^[6789][0-9]{9}$/i.test(mobile);
    //return regex.test(mobile);
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9.]+@[a-zA-Z]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name') {
      setName(value);
      if (!value.trim()) {
        setErrors({ ...errors, [name]: "Name is required" });
      } else {
        setErrors({ ...errors, [name]: "" });
      }

    } else if (name === 'mobile' && value.length <= 10) {

      setMobile(value);
      if (!value.trim()) {
        setErrors({ ...errors, [name]: "Mobile is required" });
      } else if (!validateMobile(value)) {
        setErrors({ ...errors, [name]: "Invalid Mobile number" });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    } else if (name === 'email') {
      setEmail(value);
      /* if (value.trim() && !validateEmail(value)) {
        setErrors({ ...errors, [name]: "Invalid Email Address" })
      } else {
        setErrors({ ...errors, [name]: "" });
      } */
    }
  };

  const formatDate = (date) => {
    const [year, month, day] = date.split('-');
    return (date === '0000-00-00' ? " " : `${year}-${month}-${day}` ) 
  };
  const today = new Date().toISOString().split('T')[0]; 

  const profileEdit = (e) => {
    e.preventDefault();
    const errors = {};
    if (!name.trim() || name === '') {
      errors.name = "Enter your name";
    }else if(name.length< 3){
      errors.name = "Invalid user name";
    }
    if (!mobile.trim() || mobile === '') {
      errors.mobile = "Enter mobile number";
    }
    if(d_o_b =='0000-00-00'){
      //errors.d_o_b = "";
    }else{
      if(d_o_b != ''){
        if(d_o_b > today){
          errors.d_o_b = "Invalid date of birth";
        }
        if(d_o_b < '1900-01-01'){
          errors.d_o_b = "Invalid date of birth";
        } 
      }
    }
    if(email!='' && !validateEmail(email)){
      errors.email = "Invalid email";
    }
      // if (d_o_b === '0000-00-00') {
      //   errors.d_o_b = "Enter date of birth";
      // }else{
      //   setErrors({...errors ,[name] : ""});
      // }
    if (Object.keys(errors).length === 0) {
      const formattedDob = formatDate(d_o_b);
      postProfileEdit(userId, name, mobile, email, gender, formattedDob)
        .then(response => {
          setModal({ show: true, type: 'success', title: 'Success', message: response.data.message });
          setErrors('');
        })
    }
    else {
      setErrors(errors);
    }
  };

  return (
    <div>
      <Header />
      <section className="my-order mt-space section-pad">
        <Container>
          <Row>
            <Col lg={2} md={3} xs={12}>
              <ProfileSidemenu />
            </Col>
            <Col lg={9} md={9} xs={12}>
              <div className="profile-account">
                <h4>My Profile</h4>
              </div>
              <div className="profile-update">
                <Row>
                  <Col lg={6} md={12} xs={12}>
                    <div className="form-location inputbt-space">
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="label-text">
                          Full Name <b>*</b>
                          {/* <span className="errortext">Required</span> */}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="enter your full name"
                          name="name"
                          value={name}
                          // onChange={(e) => setName(e.target.value)}
                          onChange={handleChange}
                          isInvalid={!!errors.name}
                          maxLength={50}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Col>

                  <Col lg={6} md={12} xs={12}>
                    <div className="form-location inputbt-space valu-text">
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="label-text">Mobile</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="enter your phone number"
                          name="mobile"
                          value={mobile}
                          onChange={handleChange}
                          isInvalid={!!errors.mobile}
                          maxLength={10}
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.mobile}
                        </Form.Control.Feedback>

                        {/* {!validateMobile(mobile) ? (<Form.Text className="error text-danger">Invalid mobile number</Form.Text>) : ('')} */}
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={6} md={12} xs={12}>
                    <div className="form-location inputbt-space">
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="label-text">Email ID </Form.Label>
                        <Form.Control type="email"
                          placeholder="email id"
                          name="email"
                          value={email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                        />
                        {/* {!validateEmail(email) && <Form.Text className="error text-danger">Invalid email address</Form.Text>} */}
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Col>

                  <Col lg={6} md={12} xs={12}>
                    <div className="form-location inputbt-space">
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="label-text">
                          Date of Birth
                        </Form.Label>
                        <Form.Control type="date"
                          placeholder="date of Birth"
                          value={d_o_b}
                          min="1900-01-01" 
                          max={today}
                          onChange={(e) => setDob(e.target.value)}
                          isInvalid={!!errors.d_o_b}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.d_o_b}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Col>

                  <Col lg={6} md={12} xs={12}>
                    <div className="form-location inputbt-space form-group">
                      <Form.Label className="label-text">Gender</Form.Label>
                      <Form.Select aria-label="Select Gender" value={gender} onChange={(e) => setGender(e.target.value)}>
                        <option value="0">Select Gender</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                        <option value="3">Other</option>
                      </Form.Select>
                    </div>
                  </Col>

                  <Col lg={12} md={12} xs={12}>
                    <div className="add-button">
                      {/* <Button variant="primary" to="/Home" className="btn-lg btn-cancel" type="submit">Cancel</Button> */}
                      <Button
                        variant="primary"
                        to="/Home"
                        className="btn-lg btn-submit btn-size"
                        type="submit"
                        onClick={profileEdit}
                      >
                        Update
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
      <ModalWindow data={modalData} />
    </div>
  );
};

export default MyProfile;
