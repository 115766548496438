import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getOrderSettings, postRegister, postResendOtp } from "../Services/serviceApi";
import { postVerifyOtp } from "../Services/serviceApi";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { v4 as uuid } from 'uuid';
import Header from "./Header";

const SignIn = ({ lgShow, setLgShow, setSignInDetails, url, productData, setVariantModal }) => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const [visibleDiv, setVisibleDiv] = useState(1);
    const [fullName, setFullName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [cookies, setCookie] = useCookies(['SESSIONINFO'], ['LOGGEDINFO']);
    const [formErrors, formError] = useState({ fullName: '', mobileNumber: '', otp: '' });
    //const [signInDetails, setSignInDetails] = useState('')
    const [resendOtpResponse, setResendOtpResponse] = useState({});
    const [resendOtpSuccess, setResendOtpSuccess] = useState(false);
    const [isLoad, setIsLoad] = useState('Sign In');
    const [data, setData] = useState('');
    const [isOtpLoad, setIsOtpLoad] = useState('Verify');
    const [responseMsg, setResponseMsg] = useState({});
    const [minimumAmount, setMinimumAmount] = useState('');
    const [maximumAmount, setMaximumAmount] = useState('');

    const userId = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? cookies.LOGGEDINFO : cookies.SESSIONINFO;
    useEffect(() => {

        if (cookies.SESSIONINFO == undefined) {
            const unique_id = uuid();
            setCookie('SESSIONINFO', JSON.stringify(unique_id));
        }
        if (setSignInDetails == '' || setSignInDetails != undefined) {
            const signin = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? 'Sign out' : 'Sign In';
            setSignInDetails(signin)
        }
        orderSettings()
    }, [])

    const orderSettings = () => {
        getOrderSettings()
          .then(response => {
            setMinimumAmount(response[0].minimum_purchase)
            setMaximumAmount(response[0].maximum_purchase)
          })
      }

    const showDiv = (divId) => {
        setVisibleDiv(divId);
    }

    const validateMobile = (mobile) => {
        return /^[6789][0-9]{9}$/i.test(mobile);
        //return regex.test(mobile);
    };

    const validateOtp = (otp) => {
        const regex = /^[0-9]{4}$/;
        return regex.test(otp);
    };

    const handleChange = (e) => {
        const error = {};
        const eTarget = e.target;
        // eslint-disable-next-line default-case
        switch (eTarget.name) {
            case 'fullName':
                setFullName(eTarget.value)
                break;
            case 'mobileNumber':
                setMobileNumber(eTarget.value)
                break;
            case 'otp':
                setResendOtpSuccess(false);
                if (eTarget.value.trim() && !validateOtp(eTarget.value)) {
                    formError({ ...error, [eTarget.name]: "Invalid otp" })
                } else {
                    formError({ ...error, [eTarget.name]: "" });
                }
                setOtp(eTarget.value)
                break;
        }
    };

    const RegisterUser = (divId) => {
        setIsLoad("Wait...")
        const error = {};
        // if (fullName == '' || fullName == null){
        //     error.fullName = 'Enter Full name';
        //     setIsLoad("Sign In")
        // }
        // else if (fullName.length < 3) {
        //     error.fullName = "Invalid user name"
        //     setIsLoad("Sign In")
        // }
        if (mobileNumber == '' || mobileNumber == null){
            setIsLoad("Sign In")        
            error.mobileNumber = 'Enter mobile number';
        }
        else if (!validateMobile(mobileNumber)) {
            error.mobileNumber = "invalid mobile number";
            setIsLoad("Sign In")
        }

        if (Object.keys(error).length == 0) {
            postRegister(mobileNumber)
                .then(response => {
                    setIsLoad("Sign In")
                    setResponseMsg(response.data)
                    if(response.data.status ===1){
                    setData(response.data.otp)
                    setIsLoad('Sign In')
                    setVisibleDiv(divId);
                    }
                });
        }
        formError(error)
    }
    const [successMsg, setSuccessMsg] = useState(false);
    const otpVerification = () => {
        setIsOtpLoad("Wait...")
        setResendOtpSuccess(false);
        const error = {};
        if (otp == '' || otp == null)
            error.otp = 'Enter 4 digit otp';

        if (Object.keys(error).length == 0) {
            postVerifyOtp(mobileNumber, otp, userId)
                .then(response => {
                    setIsOtpLoad('Verify')
                    if (response.data.status === 1) {
                        setCookie('LOGGEDINFO', JSON.stringify(response.data.users.id), { path: '/' });
                        setSuccessMsg(true);
                        setVisibleDiv(1);
                        setLgShow(false)
                        setSignInDetails('Sign out');
                        setTimeout(() => {

                            if (url === '/') {
                                window.location.reload();
                            }
                            else if (url === 'productdetail') {
                                window.location.reload();
                            }
                            else if (productData != undefined) {
                                if (productData.totalPrice >= minimumAmount &&
                                    productData.totalPrice <= maximumAmount &&
                                    productData.outOfStock.length === 0 &&
                                    productData.productPriceChange.length === 0 &&
                                    productData.variantChange.length === 0 &&
                                    productData.inActiveProduct.length === 0) {
                                    navigate(url)
                                }else{
                                    if(setVariantModal !=undefined){
                                        setVariantModal(1,response.data.users.id);
                                    }
                                    else{
                                        setVariantModal(0);
                                    }
                                }
                                //window.location.reload();
                                    
                            }   
                            else{
                                navigate(url)
                            }
                        }, [2000]);

                    }
                    else if (response.data.status === 2) {
                        navigate(showDiv(3))
                        error.otp = response.data.message;
                    }
                });
        }
        formError(error)
    };

    const resendOtp = (divId) => {
        setResendOtpSuccess(false);
        const error = {};
        setVisibleDiv(divId);
        setOtp('')
        postResendOtp(mobileNumber)
            .then(response => {
                if (response.data.status === 1) {
                    setResendOtpResponse(response.data)
                    setData(response.data.otp)
                    setTimeout(() => {
                        setResendOtpSuccess(true);
                    }, [2000])
                }
                if (response.data.status === 2) {
                    error.otp = response.data.message;
                    navigate(showDiv(3))
                    return;
                }
            });
        formError(error)
    };

    const onCloseModal = () => {
        setLgShow(false)
        formError({});
        if (visibleDiv === 1) {
            setFullName('');
            setMobileNumber('');
        }
        setOtp('');
        setResendOtpSuccess(false);
    }

    return (
        <>
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => { onCloseModal() }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="popup-box popup-home">
                    <Row>
                        <Col lg={6} md={3} xs={6}>
                            <div className="delivery-box text-start">
                                <h4>
                                    Get started to
                                    <br />
                                    support our farmers
                                </h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur <br />
                                    adipiscing elit, sed do eiusmod tempor
                                </p>
                                <div className="popup-img">
                                    <img src="../assets/images/bag-2.svg" alt="" />
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={3} xs={12}>
                            <div className="location-modal">
                                <div className="logo-point">
                                    <img src="../assets/images/Logo_animation-2.gif" alt="" />
                                </div>

                                <Modal.Header className="btn-head" closeButton></Modal.Header>
                                <Modal.Body>

                                    <div
                                        className="loginbox"
                                        style={{ display: visibleDiv === 1 ? "block" : "none" }}
                                    >
                                        <div className="header-modal">
                                            <h4>Sign In</h4>
                                        </div>
                                        <div className="form-modal">
                                            <p>Sign In your account to get started!</p>
                                            <Row>
                                                <Col lg={12} md={12} xs={12}>
                                                    <div className="form-location">
                                                        <Form onSubmit={RegisterUser} className="mt-2">
                                                            {/* <Form.Group
                                                                className="form-group mb-3"
                                                                controlId="formBasicFullName"
                                                            >
                                                                <Form.Label className="label-text">
                                                                    Full Name <b>*</b>
                                                                    {formErrors.fullName != '' && formErrors.fullName != undefined && (<Form.Text className="error text-danger errortext">{formErrors.fullName}</Form.Text>)}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="fullName"
                                                                    placeholder="enter your full name"
                                                                    value={fullName}
                                                                    onChange={handleChange}
                                                                    isInvalid={!!errors.fullName}
                                                                    maxLength={20}
                                                                />

                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.fullName}
                                                                    </Form.Control.Feedback>
                                                            </Form.Group> */}
                                                            <Form.Group
                                                                className="form-group mb-4"
                                                                controlId="formBasicEmail"
                                                            >
                                                                <Form.Label className="label-text">
                                                                    Mobile <b>*</b>
                                                                    {formErrors.mobileNumber != '' && formErrors.mobileNumber != undefined && (<Form.Text className="error text-danger errortext">{formErrors.mobileNumber}</Form.Text>)}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="tel"
                                                                    name="mobileNumber"
                                                                    placeholder="enter mobile number"
                                                                    value={mobileNumber}
                                                                    onChange={handleChange}
                                                                    //isInvalid={!!errors.mobile}
                                                                    maxLength='10'
                                                                    required
                                                                />

                                                                {/* <Form.Control.Feedback type="invalid">
                                                                {errors.mobile}
                                                                </Form.Control.Feedback> */}
                                                                {/* {!isValidMobile && <Form.Text className="error text-danger">Invalid Mobile Number</Form.Text>} */}

                                                            </Form.Group>
                                                            {responseMsg && <p className='text-danger'>{(Object.keys(responseMsg).length > 0 && responseMsg.status == 2) ? responseMsg.message : ''}</p>}

                                                        </Form>

                                                        <Button
                                                            variant="primary"
                                                            to="/Home"
                                                            className="btn-lg w-100 home-btn"
                                                            type="submit"
                                                            onClick={() => RegisterUser(3)}
                                                        >
                                                            {isLoad}
                                                        </Button>

                                                        {/* <p className="account-sign mt-2">
                                                        I didn’t have account ?
                                                        <span className="text-green">
                                                            <Link onClick={() => showDiv(1)} to="/">

                                                            Sign Up
                                                            </Link>
                                                        </span>
                                                        </p> */}
                                                    </div>
                                                    <div className="skip-btn" onClick={handleClose}></div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    <div
                                        className="verifyotpbox"
                                        style={{ display: visibleDiv === 3 ? "block" : "none" }}
                                    >
                                        <div className="header-modal">
                                            <h4>Verify OTP</h4>
                                        </div>
                                        <div className="form-modal">
                                            <p>SMS send to your mobile</p>
                                            <Row>
                                                <Col lg={12} md={12} xs={12}>
                                                    <div className="form-location">
                                                        <Form onSubmit={otpVerification} className="mt-2">

                                                            <Form.Group
                                                                className="form-group "
                                                                controlId="formBasicEmail"
                                                            >
                                                                <Form.Label className="label-text">
                                                                    Enter OTP<b>*</b> {formErrors.otp != '' && formErrors.otp != undefined && (<Form.Text className="error text-danger errortext">{formErrors.otp}</Form.Text>)}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    name="otp"
                                                                    type="text"
                                                                    placeholder="4 digit number"
                                                                    value={otp}
                                                                    onChange={handleChange}
                                                                    //isInvalid={!!errors.otp}
                                                                    maxLength='4'
                                                                    required
                                                                />
                                                                {resendOtpSuccess && <p className='text-success'>{(Object.keys(resendOtpResponse).length > 0 && resendOtpResponse.status == 1) ? resendOtpResponse.message : ''}</p>}
                                                                
                                                            </Form.Group>
                                                            <p className='otp'>
                                                            Your otp is {data}
                                                            </p>
                                                            <p className="resend">
                                                                
                                                                <Link onClick={() => resendOtp(3)} >Resend OTP</Link>
                                                            </p>
                                                        </Form>
                                                        <Button
                                                            variant="primary"
                                                            // to="/"
                                                            className="btn btn-primary btn-lg w-100  home-btn"
                                                            type="submit"
                                                            onClick={() => otpVerification()}
                                                        >
                                                            {isOtpLoad}
                                                        </Button>

                                                    </div>
                                                    {(successMsg) && <p className='text-success text-center mt-2' >Thanks for your verification</p>}
                                                    <div className="skip-btn" onClick={handleClose}></div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>

            {/* <Header signInDetails={signInDetails} /> */}
        </>
    );
}

export default SignIn;

