import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';

// import About from './Pages/About';

import Home from './Pages/Home';
import Login from './Pages/Login';
import Cart from './Pages/Cart';
import ProductList from './Pages/ProductList';




import ScrollToTop from "react-scroll-to-top";
import { ReactComponent as MySVG } from "./scroll-top.svg";
import About from "./Pages/About";
import ProductDetail from "./Pages/ProductDetail";
import Order from "./Pages/Order";
import Checkout from "./Pages/Checkout";
import OrderSuccess from "./Pages/OrderSuccess";
import MyProfile from "./Pages/MyProfile";
import MyOrders from "./Pages/MyOrders";
import OrderDetails from "./Pages/OrderDetails";
import ShippingAddress from "./Pages/ShippingAddress";
import MyFavourite from "./Pages/MyFavourite";
import TrackOrders from "./Pages/TrackOrders";
import Faq from "./Pages/Faq";
import AboutUs from "./Pages/AboutUs";
import WhyUs from "./Pages/WhyUs";
import Testimonials from "./Pages/Testimonials";
import ShippingPolicy from "./Pages/ShippingPolicy";
import ReturnPolicy from "./Pages/ReturnPolicy";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsConditions from "./Pages/TermsConditions";
import ContactUs from "./Pages/ContactUs";
import Header from "./Components/Header";
import TodaySpecial from "./Pages/TodaySpecial";
import Search from "./Pages/Search";
import Categories from "./Pages/Categories";

function App() {
  useEffect(() => {
    AOS.init({ disable: 'mobile' });
  }, []);

  window.addEventListener('scroll', function () {
    var body = document.querySelector('body');
    var scrollPosition = window.scrollY;
    if (scrollPosition > 0) {
      body.classList.add('scrolled');
    } else {
      body.classList.remove('scrolled');
    }
  });

  return (
    <>
      <Routes>
        <Route path="/search/:id" element={<Search />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/return-policy" element={<ReturnPolicy />} />
        <Route path="/shipping-policy" element={<ShippingPolicy />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/why-us" element={<WhyUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/track-orders" element={<TrackOrders />} />
        <Route path="/my-favourite" element={<MyFavourite />} />
        <Route path="/shipping-address" element={<ShippingAddress />} />
        <Route path="/order-details" element={<OrderDetails />} />
        <Route path="/my-orders" element={<MyOrders />} />
        <Route path="/my-profile" element={<MyProfile />} />
        <Route path="/order-success/:orderId" element={<OrderSuccess />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/Order" element={<Order />} />
        <Route path="/product-detail/:id" element={<ProductDetail />} />
        <Route path="/about" element={<About />} />
        <Route path="/category/:id?" element={<ProductList />} />
        <Route path="/today-special/:id" element={<TodaySpecial />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/" element={<Home />} />
        <Route path="/Login" element={<Login />} />
        <Route path="*" element={<Home />} />
      </Routes>

      <ScrollToTop smooth component={<MySVG />} />
    </>
  );

}


var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("remove-top").style.top = "0";
  } else {
    document.getElementById("remove-top").style.top = "-40px";
  }
  prevScrollpos = currentScrollPos;
}

const imgs = document.querySelectorAll('.img-select a');
const imgBtns = [...imgs];
let imgId = 1;

imgBtns.forEach((imgItem) => {
  imgItem.addEventListener('click', (event) => {
    event.preventDefault();
    imgId = imgItem.dataset.id;
    slideImage();
  });
});


function slideImage() {
  const displayWidth = document.querySelector('.img-showcase img:first-child').clientWidth;

  document.querySelector('.img-showcase').style.transform = `translateX(${- (imgId - 1) * displayWidth}px)`;
}

window.addEventListener('resize', slideImage);

var thumbnails = document.getElementById("thumbnails")
// var imgs = thumbnails.getElementsByTagName("img")
var main = document.getElementById("main")
var counter = 0;

for (let i = 0; i < imgs.length; i++) {
  let img = imgs[i]


  img.addEventListener("click", function () {
    main.src = this.src
  })

}

// image viewer














export default App;
