import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import InputGroup from "react-bootstrap/InputGroup";
import { useCookies } from 'react-cookie';
import ToastMessage from "../Components/toast-message";
import ModalWindow from "../Components/ModalWindow";
// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ProfileSidemenu from "../Components/ProfileSidemenu";
import { getCartCount, getCartInsert, postCartUpdate, postFavouriteList, postFavouriteRemove } from "../Services/serviceApi";

const MyFavourite = () => {
   const [favouriteProducts, setFavouriteProducts] = useState([] || '')
   const [cartCount, setCartCount] = useState(0);
   const [cookies] = useCookies(['SESSIONINFO'], ['LOGGEDINFO']);
   const [show, setShow] = useState(false);
   const [modalData, setModal] = useState({show:false,type:'',title:'',message:''})
   const [productIds, setProductIds] = useState('');
  const [cartMsg,setCartMsg] = useState('')
  const [userData, setUserData] = useState('');

   const userId = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? cookies.LOGGEDINFO : cookies.SESSIONINFO;
   const userType = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? '' : 'guest';

   const getFavouritesList = () => {
      postFavouriteList(userId, userType).then(response => {
         setFavouriteProducts(response.data.favouritelist)
      })
      return [favouriteProducts];
   }

   const favouriteDelete = (userId, productId) => {
      setProductIds(productId)
      setModal({ show: true, type: 'confirmation', title: 'Remove from Favourites', message: 'Are you sure you want to remove this product from your favourites ?' });
   }

   const onCloseModel = (type) => {
      if (type == 1) {
         postFavouriteRemove(userId, productIds)
         .then(response => {
            getFavouritesList();
            setUserData(response)
         })
      }
    };

   const addToCart = (productId) => {
      getCartInsert(userType, userId, productId)
         .then(response => {
            setCartMsg(response.data.message)
            setShow(true)
            updateQuantityChange(productId, '');
            setUserData(response)
         })
   };

   const updateQuantityChange = (productId, action) => {
      const updatedProducts = favouriteProducts.map((product) => {
         if (product.product_id === productId) {
            let currentQuantity = parseInt(product.cart_qty) || 0;
            let updatedQuantity;
            if (action === 'increment') {
               updatedQuantity = currentQuantity + 1;
               setShow(true)
               setCartMsg('Success! Quantity added to your cart. Keep on shopping!')
            } else if (action === 'decrement' && currentQuantity > 0) {
               updatedQuantity = currentQuantity - 1;
               if(updatedQuantity == 0){
                  setShow(true)
                  setCartMsg('Product successfully removed from the cart.')
                }else{
                  setShow(true)
                  setCartMsg('Quantity reduced successfully. Your cart is updated.')
                }
              }else if(action == ''){
                updatedQuantity = 1;
                setShow(true)
            } else {
               return product;
            }
            postCartUpdate(userId, productId, updatedQuantity, userType)
               .then(response => {
                  setUserData(response)
                  setCartCount(response)
               })
            return {
               ...product,
               cart_qty: updatedQuantity
            };
         }
         return product;
      });
      setFavouriteProducts(updatedProducts);
   };

   const getCartCounts = () => {
      getCartCount(userId, userType).then(response => {
         setCartCount(response)
      })
   }

   useEffect(() => {
      getFavouritesList()
   }, [])

   return (
      <div>
         <Header cartCount={cartCount} />
         <section className="my-order mt-space section-pad">
            <Container>
               <Row>
                  <Col lg={2} md={4} xs={12}>
                     <ProfileSidemenu />
                  </Col>

                  <Col lg={9} md={8} xs={12}>
                     <div className="order-top">
                        <h4>My Favourites</h4>
                     </div>
                     {Array.isArray(favouriteProducts) && favouriteProducts.length > 0 ? (
                        <Row>
                           {favouriteProducts.map((product, index) => (
                              <Col lg={4} md={6} xs={6} key={index}>
                                 <div className="product-box detail-box">
                                    <Link to={`/product-detail/${product.product_slug}`} /* onClick = {()=>{getSlugData()}} */ state={{ product }}>
                                       <div className="product-img">
                                          <img
                                             className="zoom-effect"
                                             src={product.product_image}
                                             alt=''
                                          />
                                          {parseInt(product.offer_price)!== 0 && product.offer_price != null ? (
                                             <div className="product-offer-box offer-orange">
                                                <span>
                                                   <strong>{Math.round((product.price - product.offer_price) / product.price * 100)}%</strong> <br />
                                                   <span className="off-text">OFF</span>
                                                </span>
                                             </div>
                                          ) : ('')}
                                       </div>
                                       <div className="fav-icon">
                                          <Link onClick={() => favouriteDelete(userId, product.product_id)}>
                                             <span className="iconify" >
                                                <Icon icon="material-symbols:delete-outline" />
                                             </span>
                                          </Link>
                                       </div>
                                       <div className="product-title">
                                          <h4>
                                          {product.product_name.charAt(0).toUpperCase() + product.product_name.slice(1)} / {product.product_name2} - {product.variant_name+' '+product.nic_name}
                                          </h4>
                                       </div>
                                    </Link>
                                    <div className="select-card">
                                       <Row className="d-flex align-items-center">
                                          <Col lg={6} md={6} xs={12}>
                                             <div className="price-list">
                                                <h5>
                                                   <span className="price-tag">
                                                      ₹{(parseInt(product.offer_price)!== 0 && product.offer_price != null) ?product.offer_price : product.price}
                                                   </span>
                                                   {(parseInt(product.offer_price)!== 0 && product.offer_price != null) &&<>
                                                   <span className="price-low">
                                                      ₹{product.price}
                                                   </span>
                                                   <br /> You save ₹{product.price - product.offer_price}</>}
                                                </h5>
                                             </div>
                                          </Col>
                                          <Col lg={6} md={6} xs={12}>
                                             {product.cart_qty === null || product.cart_qty === 0 ? (

                                                <div
                                                   className="cart-btn"
                                                >
                                                   {product.stock_availability == 0 ? (<p className="out_of_stock">Out of Stock</p>) : (
                                                      <Link onClick={() => addToCart(product.product_id)} >
                                                         <span className="iconify">
                                                            <Icon icon="solar:cart-4-linear" />
                                                         </span>
                                                        <span className="cart-hide">Add to Cart</span>
                                                      </Link>
                                                   )}
                                                </div>) : (
                                                <div
                                                   className="price-add"
                                                > {((product.stock_availability == 0) ?
                                                   <p className="out_of_stock">Out of Stock</p> :
                                                   (product.cart_qty !== null || product.cart_qty !== 0) ? (

                                                      <div className="quantity-price mt-0">
                                                         <Button className="qty-button" onClick={() => updateQuantityChange(product.product_id, 'decrement')}>
                                                            <span className="iconify">
                                                               <Icon icon="ic:baseline-minus" />
                                                            </span>
                                                         </Button>
                                                         <InputGroup className="qty-count">
                                                            <Form.Control
                                                               placeholder="1"
                                                               aria-label="Username"
                                                               aria-describedby="basic-addon1"
                                                               value={product.cart_qty}
                                                               onChange={(e) => updateQuantityChange(product.product_id, e.target.value)}
                                                            />
                                                         </InputGroup>
                                                         <Button className="qty-button" onClick={() => updateQuantityChange(product.product_id, 'increment')}>
                                                            <span className="iconify">
                                                               <Icon icon="ic:baseline-plus" />
                                                            </span>
                                                         </Button>
                                                      </div>
                                                   ) : ('')
                                                )}
                                                </div>
                                             )}

                                          </Col>
                                       </Row>
                                    </div>
                                 </div>
                              </Col>
                           ))}
                        </Row>
                     ) : (
                        <p className="empty-address">No favourite items found</p>
                     )}
                  </Col>
               </Row>
            </Container>
         </section>
         <ToastMessage show={show} setShow={setShow} cartMsg={cartMsg}/>
         <ModalWindow data={modalData} onCloseModel={onCloseModel}/>
         <Footer />
      </div>
   );
};

export default MyFavourite;
