import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const Login = () => {
  return (
    <div>
      {/* <Header /> */}
      <section className="section header-nxt">
        <Container fluid="md">
          <Row>
            <Col lg={5} className="offset-lg-4">
              <div className="login-cont">
                <div className="login-logo">
                  <img src="../assets/images/logo.svg" alt="" />
                </div>
                <h3>Sign In</h3>
                <Form className="mt-2">
                  <Form.Group
                    className="form-group mb-2"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Email Address <span className="req">*</span>
                    </Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                    <Form.Text className="error text-danger">
                      Invalid Email Address
                    </Form.Text>
                  </Form.Group>

                  <Form.Group
                    className="form-group mb-1"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>
                      Password <span className="req">*</span>
                    </Form.Label>
                    <Form.Control type="password" placeholder="Password" />
                    {/* <div className="eye"><Icon icon="mdi:eye-off" /></div> */}
                  </Form.Group>
                  <div className="forgot-row mt-2 mb-2">
                    <Link to="/ForgotPassword" className="fwd-password">
                      Forgot Password?
                    </Link>
                  </div>
                  <Button
                    variant="primary"
                    to="/Home"
                    className="btn-lg w-100 btn-submit"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form>
                <p className="reg-text">
                  Don't have an account <Link to="/Home">Sign Up?</Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <Footer /> */}
    </div>
  );
};

export default Login;
