import React,{useEffect,useState} from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Icon } from "@iconify/react";
/* import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion"; */

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const TermsConditions = () => {

  const [pageContent,setPageContent] = useState([]);

  useEffect(()=>{
    const content = localStorage.getItem('cmsData');
    const content1 = JSON.parse(content)
    if(content1 !=null){
      setPageContent(content1)
    }
  },[])

  const getTermsContent = () =>{
    const content = [];
    if(pageContent !=null && pageContent.length > 0){
      pageContent.map((data)=>{
        content.push(
          <div className="ploicy-box para-text">
           <div dangerouslySetInnerHTML={{__html:data.description}}></div>
          </div>
        )
      })
      return content;
    }
  }

  return (
    <div>
      <Header />
      <section className="order-success mt-space">
        <Container>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to ='/'>
                  Home
                  <span className="iconify">
                    <Icon icon="iconamoon:arrow-right-2-duotone" />
                  </span>
                </Link>
              </li>
              {(pageContent !=null && pageContent.length > 0) && <li className="active">{pageContent[0].page_name}</li>}
            </ul>
          </div>
          <div className="order-top">
          {(pageContent !=null && pageContent.length > 0) && <h4>
            {pageContent[0].title}
          </h4>}
            {/* <h4>Terms & Conditions</h4> */}
          </div>
          <Row>
            <Col lg={12} md={12} xs={12}>
              {getTermsContent()}
              {/* <div className="ploicy-box para-text">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged.
                </p>

                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged.
                </p>
              </div> */}
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </div>
  );
};

export default TermsConditions;
