// import React from "react";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Icon } from '@iconify/react';
import React, { useState, useCallback } from 'react';
import { render } from 'react-dom';
import ImageViewer from 'react-simple-image-viewer';
import ReactImageMagnify from 'react-image-magnify';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const About = () => {  
    
  const images = [

          '../assets/images/works-img1.jpg',
          '../assets/images/works-img2.jpg',
          '../assets/images/works-img3.jpg',
          '../assets/images/works-img4.jpg'
          
  ]
  const [img,setImg]=useState(images[0])
  const hoverHandler = (images, i) => {
    setImg(images);
  } 
   
  
  return (
    <div>
        <Header />
          
          <section className="mt-space">
               <Container>
                <div className="left">
              
                  <div className="left-1">
                      {images.map((images, i) => (
                          <div className={"i == 0 ? 'img-wrap active' : img-wrap"} key={i}
                          onMouseOver={() => hoverHandler(images, i)}>
                             <img src={images} alt=""/>
                          </div>
                        ))
                      }

                  </div>
                  <div className="left-2">

                  <ReactImageMagnify {...{
            smallImage: {
               alt: 'ProductImage',
                isFluidWidth: true,
                src: img,
            },
            largeImage: {
                src: img,
                width: 1400,
                height: 1200,
            },
            isHintEnabled: true,
            enlargedImagePosition: 'over',
            // lensStyle: {backgroundColor:'rgba(0,0,0,0.9)'}
            
               }} />

                  </div>
                  <div className="left-3"></div>
                  <div className="left-4"></div>

                </div>

               </Container>
          </section>
        
        
        <Footer />
    </div>
  );
};

export default About;
