import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

const Availability = ({ data, onClosePopup }) => {
  const { title, message, type } = data || {}
  const [isModalVisible, setModalVisible] = useState(data.show);
  useEffect(() => {
    setModalVisible(data.show);
  }, [data]);

  const closeModal = (e, type) => {
    e.preventDefault();
    if (type != 'backdropClick')
      setModalVisible(false);
    if (onClosePopup != undefined && onClosePopup != '')
      onClosePopup(type);
  }

  return <Modal show={isModalVisible} centered className="alertModals" size='lg'>
    <div className="popup-content">
      <Row>
        <Col lg={12} md={12} xs={12}>
          <div className="location-modal checkout-popup">
            {/* <Button className="closeModal" onClick={closeModal}><Icon icon="ion:close-outline" /></Button> */}
            <Modal.Body>
              {Array.isArray(message[0]) ?
                <>
                  <h6>{title[0]}</h6>
                  <div className="pop-box-space">
                    {message[0].map((product, index) => (
                      <div className='cart-pop-box'>
                        <Row className='d-flex align-items-center'>
                          <Col lg={2} md={4} xs={4}>
                            <div className='cart-img-modal'>
                              <img src={product.product_image}
                                alt={product.product_image} />
                            </div>
                          </Col>
                          <Col lg={10} md={8} xs={8}>
                            <div className='cart-price-modal'>
                              <p>{product.product_name}</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                </> : ''}
              {Array.isArray(message[1]) ?
                <>
                  <h6>{title[1]}</h6>
                  <div className="pop-box-space">
                    {message[1].map((product, index) => (
                      <div className='cart-pop-box'>
                        <Row className='d-flex align-items-center'>
                          <Col lg={2} md={4} xs={4}>
                            <div className='cart-img-modal'>
                              <img src={product.product_image}
                                alt={product.product_image} />
                            </div>
                          </Col>
                          <Col lg={10} md={8} xs={8}>
                            <div className='cart-price-modal'>
                              <p>{product.product_name}</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                </> : ''}
              {Array.isArray(message[2]) ?
                <>
                  <h6>{title[2]}</h6>
                  <div className="pop-box-space">
                    {message[2].map((product, index) => (
                      <div className='cart-pop-box'>
                        <Row className='d-flex align-items-center'>
                          <Col lg={2} md={4} xs={4}>
                            <div className='cart-img-modal'>
                              <img src={product.product_image}
                                alt={product.product_image} />
                            </div>
                          </Col>
                          <Col lg={10} md={8} xs={8}>
                            <div className='cart-price-modal'>
                              <p>{product.product_name}</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                </> : ''}
              {Array.isArray(message[3]) ?
                <>
                  <h6>{title[3]}</h6>
                  <div className="pop-box-space">
                    {message[3].map((product, index) => (
                      <div className='cart-pop-box'>
                        <Row className='d-flex align-items-center'>
                          <Col lg={2} md={4} xs={4}>
                            <div className='cart-img-modal'>
                              <img src={product.product_image}
                                alt={product.product_image} />
                            </div>
                          </Col>
                          <Col lg={10} md={8} xs={8}>
                            <div className='cart-price-modal'>
                              <p>{product.product_name}</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                </> : ''}


             
            </Modal.Body>
            
          </div>
          <div className="alert-box">
                {(type == 'success' || type == 'error') && <Button className="contained btn-big yellow-btn" onClick={e => closeModal(e, 1)}>Ok</Button>}
                {(type == 'confirmation') && <><Button variant="light" onClick={e => closeModal(e, 0)}>No</Button>
                  <Button variant="primary" onClick={e => closeModal(e, 1)}>Yes</Button></>}
              </div>
        </Col>
      </Row>
    </div>
  </Modal>
};

export default Availability;