import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Icon } from "@iconify/react";
import Accordion from "react-bootstrap/Accordion";

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { getFaq } from "../Services/serviceApi";

const Faq = () => {
  const [pageContent, setPageContent] = useState([]);

  const getFaqContent = () => {
    getFaq().then(response => {
      setPageContent(response.data)
    })
  }

  const getFaqs = () => {
    const content = [];
    pageContent.map((faq) => {
      content.push(
      <Accordion.Item key={faq.id} eventKey={faq.id}>
        <Accordion.Header>
          {faq.question}
        </Accordion.Header>
        <Accordion.Body>
          <div className="">
            <p className="feedback-text">
            {faq.answer}
            </p>
          </div>
        </Accordion.Body>
      </Accordion.Item>)
    })
    return content;
  }

  useEffect(() => {
    getFaqContent();
  }, [])

  return (
    <div>
      <Header />
      <section className="order-success mt-space">
        <Container>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to='/'>
                  Home
                  <span className="iconify">
                    <Icon icon="iconamoon:arrow-right-2-duotone" />
                  </span>
                </Link>
              </li>
              <li className="active">FAQ</li>
            </ul>
          </div>
          <div className="order-top text-center">
            <h4>Frequently Asked Questions</h4>
          </div>
          <Row className="d-flex justify-content-center">
            <Col lg={8} md={12} xs={12}>
              <div className="accordion-box">
                <Accordion defaultActiveKey={["1"]}>
                  {getFaqs()}
                </Accordion>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default Faq;
