import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import { useCookies } from 'react-cookie';
import ModalWindow from "../Components/ModalWindow";

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ProfileSidemenu from "../Components/ProfileSidemenu";
import { postAddress, postAddressDelete, postAddressList, postDefaultAddress, postUpdateAddress, getCityList,getPinCodeList } from "../Services/serviceApi";

const ShippingAddress = () => {
  /* const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [smShow, setSmShow] = useState(false);
  const [xlShow, setxlShow] = useState(false); */
  const [lgShow, setLgShow] = useState(false);
  const [addresses, setAddresses] = useState([] || '');
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [errors, setErrors] = useState({});
  const [cityList,setCityList] = useState([]);
  const [pinCodeList,setPinCodeList] = useState([]);
  const [modalData, setModal] = useState({show:false,type:'',title:'',message:''})
  const [cookies] = useCookies(['SESSIONINFO'], ['LOGGEDINFO']);
  const [addressId,setAddressId] = useState(0);
  const [formErrors,formError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const userId = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? cookies.LOGGEDINFO : cookies.SESSIONINFO;
  //const userType = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? '' : 'guest';


  const fetchData = ()=>{
    getCityList()
    .then(response => {
      setCityList(response);
    })
  }

  const handleCloseModal = () => {
    setLgShow(false);
    setSelectedAddress(null);
    setFormData(initialFormData);
    setErrors(initialErrors);
  };

  const initialErrors = {
    custName: '',
    custMobile: '',
    custEmail: ''
  };

  const [formData, setFormData] = useState({
    userId: '',
    custName: '',
    custMobile: '',
    custEmail: '',
    custAddress: '',
    custArea: '',
    custLandmark: '',
    custState:'Tamil Nadu',
    custCity: '' ,
    custPincode: ''
  });
  const { custName, custMobile, custEmail, custAddress, custArea, custLandmark, custState, custCity, custPincode } = formData;

  const initialFormData = {
    userId: userId,
    custName: '',
    custMobile: '',
    custEmail: '',
    custAddress: '',
    custArea: '',
    custLandmark: '',
    custState:'Tamil Nadu',
    custCity: '',
    custPincode: ''
  };

  const validateForm = () => {
    const errors = {};
    if (!custName.trim()) {
      errors.custName = "Name is required";
    }else if(custName.length<3){
      errors.custName = "Invalid customer name";
    }
    if (!custMobile.trim()) {
      errors.custMobile = "Mobile number is required";
    } else if (!validateMobile(custMobile)) {
      errors.custMobile = "Invalid mobile number";
    }
    if (custEmail.trim() && !validateEmail(custEmail)) {
      errors.custEmail = "Invalid Email address";
    } /* else if () {
      errors.custEmail = "Invalid email address";
    } */
    if (!custAddress.trim()) {
      errors.custAddress = "Address is required";
    }
    if (!custArea.trim()) {
      errors.custArea = "Area is required";
    }
    /* if (!custLandmark.trim()) {
      errors.custLandmark = "Landmark is required";
    } */
    if (!custCity.trim()) {
      errors.custCity = "City is required";
    }
    if (custState !=undefined && !custState.trim()) {
      errors.custState = "State is required";
    }
    if (!custPincode.trim()) {
      errors.custPincode = "Pincode is required";
    }
    else if (!validatePincode(custPincode)) {
      errors.custPincode = "Invalid Pincode";
    }
    return errors;
  };

  const validateMobile = (mobile) => {
    return /^[6789][0-9]{9}$/i.test(mobile);
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9.]+@[a-zA-Z]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  };

  const validatePincode = (pincode) => {
    return /^\d{6}$/i.test(pincode);
  };

  const addAddress = () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      postAddress(userId, custName, custMobile, custEmail, custAddress, custArea, custLandmark, custState, custCity, custPincode)
        .then(response => {
          setLgShow(false);
          addressListing();
          setErrors(initialErrors);
          setFormData({custState:'Tamil Nadu'});
        })
    }
    else {
      setErrors(errors)
    }
  }

  const addressListing = () => {
    postAddressList(userId)
      .then(response => {
        setAddresses(response.data.addresslist);
        setIsLoading(false)
      })
  }

  const updateAddress = () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      postUpdateAddress(userId, custName, custMobile, custEmail, custAddress, custArea, custLandmark, custState, custCity, custPincode, selectedAddress.address_id)
        .then(response => {
          setLgShow(false);
          addressListing();
          setErrors(initialErrors);
        })
    }
    else {
      setErrors(errors)
    }
  }

  const handleEditAddress = (address) => {
    setSelectedAddress(address);
    setLgShow(true);
  };

  const handleDeleteAddress = (addressId) => {
    setAddressId(addressId)
    setModal({ show: true, type: 'confirmation', title: 'Delete Address Confirmation', message: 'Are you sure want to delete Address ?' });
  }

  const onCloseModel = (type) => {
    if (type == 1) {
      postAddressDelete(userId, addressId)
      .then(response => {
        addressListing()
      })
    }
  };

  const handleDefaultAddress = (addressId) => {
    postDefaultAddress(addressId, userId)
      .then(response => {
        setDefaultAddress(addressId)
        addressListing();
        // localStorage.setItem('default_address', addressId);
      })
  }

  // useEffect(() => {
  //   const defaultAddress = localStorage.getItem('default_address');
  //   if (defaultAddress) {
  //     setDefaultAddress(defaultAddress);
  //   }
  // }, []);

  useEffect(() => {
    addressListing();
    fetchData()
    // handleDefaultAddress()
  }, [])

  useEffect(() => {
    if (selectedAddress) {
      getPincode(selectedAddress.customer_city)
      setFormData({
        userId: userId,
        custName: selectedAddress.customer_name,
        custMobile: selectedAddress.customer_mobile,
        custEmail: selectedAddress.customer_email,
        custAddress: selectedAddress.customer_address,
        custArea: selectedAddress.customer_area,
        custLandmark: selectedAddress.customer_landmark,
        custState: selectedAddress.customer_state,
        custCity: selectedAddress.customer_city,
        custPincode: selectedAddress.customer_pincode,
      });
    }
    else {
      setFormData(initialFormData)
    }
  }, [selectedAddress, userId]);

  const getPincode = (data) =>{
    getPinCodeList(data).then(response => {
      setPinCodeList(response.pincode_list)
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    const mobileRegex = /^[0-9]*$/
    let newErrors = { ...errors };
    if (name === 'custName') {
      if (!value.trim()) {
        newErrors[name] = "Name is required";
      }
      else {
        delete newErrors[name];
      }
    }
    if (name === 'custMobile') {
      if (!mobileRegex.test(value)) {
        return; 
      }
      if (!value.trim()) {
        newErrors[name] = "mobile number is required";
      } else if (!validateMobile(value)) {
        newErrors[name] = "Invalid mobile number";
      } else {
        delete newErrors[name];
      }
    }
    if (name === 'custEmail') {
      if (value.trim() && !validateEmail(value)) {
        newErrors[name] = "Invalid Email address";
      }/*  else if () {
        newErrors[name] = "Invalid email address";
      } */
      else {
        delete newErrors[name];
      }
    }
    if (name === 'custPincode') {
      if (!value.trim()) {
        newErrors[name] = "Pincode is required";
      } else if (!validatePincode(value)) {
        newErrors[name] = "Invalid pincode";
      } else {
        delete newErrors[name];
      }
    }
    if (name === 'custAddress') {
      if (!value.trim()) {
        newErrors[name] = "Address is required";
      } else {
        delete newErrors[name];
      }
    }
    if (name === 'custArea') {
      if (!value.trim()) {
        newErrors[name] = "Area is required";
      } else {
        delete newErrors[name];
      }
    }
   /*  if (name === 'custLandmark') {
      if (!value.trim()) {
        newErrors[name] = "Landmark is required";
      } else {
        delete newErrors[name];
      }
    } */
    if (name === 'custState') {
      if (!value.trim()) {
        newErrors[name] = "State is required";
      } else {
        delete newErrors[name];
      }
    }
    if (name === 'custCity') {
      if (!value.trim()) {
        newErrors[name] = "City is required";
      } else {
        delete newErrors[name];
      }
    }
    setErrors(newErrors);
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const onStateChange = (e) =>{
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
   //setState(e.target.value)
  }

  const getCityLists = () =>{
    const content = [];
    cityList.map((cityData,index)=>{
      content.push(<option key={index} value={cityData.city_name}>{cityData.city_name}</option>)
    })
    return content;
  }

  const onCityChange = (e) =>{
    const error ={};
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  
    if(value !=''){
      getPinCodeList(value).then(response => {
        if(response.status == 200){
          setPinCodeList(response.pincode_list)
        }
        else{
          error.pinCode = response.pincode_list;
          formError(error);
        }
      })
    }
    else{
      setPinCodeList([]);
    }
  }
  
  const getPinCodeLists = () =>{
    const content = [];
    // eslint-disable-next-line no-lone-blocks
    {(pinCodeList !=undefined && pinCodeList.length > 0) && pinCodeList.map((pincode)=>{
      content.push(<option value={pincode.pincode}>{pincode.pincode}</option>)
    })
    return content;}
  }

  const onPinCodeChange = (e) =>{
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    //setPinCode(e.target.value)
  }

  const addNewAddress = () =>{
    setSelectedAddress(null)
    setPinCodeList([]);
    setLgShow(true);
  }

  return (
    <div>
      <Header />
      <section className="my-order mt-space section-pad">
        <Container>
          <Row>
            <Col lg={2} md={3} xs={12}>
              <ProfileSidemenu />
            </Col>
            <Col lg={9} md={9} xs={12}>
              <Col lg={12} md={12} xs={12}>
                <div
                  className="cart-title order-top headingbtn"
                >
                  <h4>My Addresses</h4>
                  <Link onClick={() => addNewAddress()}>Add New</Link>
                </div>
                <div className="cart-body cardbg">
                  {!isLoading ? (
                    addresses !=undefined && addresses.length > 0?<><Row>
                      {addresses.map((address, index) => (
                        <Col lg={6} md={12} xs={12} key={index}>
                          <div className="address-box">
                            <div className="address-header">
                              <Form.Check type="radio" aria-label="radio 1"
                                checked={address.default_address==='1'}
                                onChange={() => handleDefaultAddress(address.address_id)} />
                              <h5>
                                {address.customer_name}
                                {address.default_address==='1' ? (
                                  <span className="badge badge-success">Default</span>
                                ) : ('')}
                              </h5>
                            </div>
                            <p>
                              <span>Address: </span>{address.customer_address},{address.customer_area}, {address.customer_city}, {address.customer_state}, {address.customer_pincode}
                            </p>
                            <p>
                            {(address.customer_landmark != '') && <span>Landmark: </span>}{address.customer_landmark}
                            </p>
                            <p>
                              <span>Contact No: </span>{address.customer_mobile}
                            </p>
                            <div className="edit-option">
                              <ul>
                                <li>
                                  <Link>
                                    <span
                                      className="iconify"
                                      onClick={() => handleEditAddress(address)}
                                    >
                                      <Icon icon="eva:edit-outline" />
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link>
                                    <span
                                      className="iconify"
                                      onClick={() => handleDeleteAddress(address.address_id)}
                                    >
                                      <Icon icon="material-symbols:delete-outline" />
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row></>:<p className="empty-address">No addresses available.</p>
                  ):<div className="loader-box"><div class="lds-ripple"><div className="loader-text"></div></div></div>}
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal
        size="xm"
        show={lgShow}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="popup-box">
          <Row>
            <Col lg={12} md={3} xs={12}>
              <div className="location-modal">
                <div className="signup-page">
                  <Modal.Header className="btn-head" closeButton>
                    <div className="header-modal">
                      <h4>Add Address</h4>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="form-modal">
                      <Row>
                        <Col lg={6} md={12} xs={12}>
                          <div className="form-location inputbt-space">

                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">
                                Customer Name<b>*</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="customer Name"
                                name="custName"
                                value={formData.custName}
                                onChange={handleChange}
                                isInvalid={!!errors.custName}
                                maxLength={20}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.custName}
                              </Form.Control.Feedback>
                            </Form.Group>

                          </div>
                        </Col>
                        <Col lg={6} md={12} xs={12}>
                          <div className="form-location inputbt-space">
                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">
                                Mobile Number<b>*</b>
                              </Form.Label>
                              <Form.Control
                                type="tel"
                                placeholder="enter mobile number"
                                name="custMobile"
                                value={formData.custMobile}
                                onChange={handleChange}
                                isInvalid={!!errors.custMobile}
                                maxLength={10}
                              />
                              {/* {!!errors.custMobile ? (<Form.Text className="error text-danger">Invalid mobile number</Form.Text>) : ('')} */}
                              <Form.Control.Feedback type="invalid">
                                {errors.custMobile}
                              </Form.Control.Feedback>

                            </Form.Group>
                          </div>
                        </Col>

                        <Col lg={6} md={12} xs={12}>
                          <div className="form-location inputbt-space">

                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">
                                Email Address<b>*</b>
                              </Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="enter email address"
                                name="custEmail"
                                value={formData.custEmail}
                                onChange={handleChange}
                                isInvalid={!!errors.custEmail}
                              />
                              {!!errors.custEmail ? (<Form.Text className="error text-danger">Invalid email address</Form.Text>) : ('')}

                            </Form.Group>
                          </div>
                        </Col>

                        <Col lg={6} md={12} xs={12}>
                          <div className="form-location inputbt-space">

                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">
                                Address<b>*</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="enter address"
                                name="custAddress"
                                value={formData.custAddress}
                                onChange={handleChange}
                                isInvalid={!!errors.custAddress}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.custAddress}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>

                        <Col lg={12} md={12} xs={12}>
                          <div className="form-location inputbt-space">

                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">
                                Area<b>*</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="enter area"
                                name="custArea"
                                value={formData.custArea}
                                onChange={handleChange}
                                isInvalid={!!errors.custArea}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.custArea}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>

                        <Col lg={12} md={12} xs={12}>
                          <div className="form-location input-textarea form-group">
                            <Form.Label className="label-text">
                              Landmark
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                as="textarea"
                                aria-label="enter landmark"
                                placeholder="enter landmark"
                                name="custLandmark"
                                value={formData.custLandmark}
                                onChange={handleChange}
                                //isInvalid={!!errors.custLandmark}
                              />
                              {/* <Form.Control.Feedback type="invalid">
                                {errors.custLandmark}
                              </Form.Control.Feedback> */}
                            </InputGroup>
                          </div>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                          <div className="form-location inputbt-space">
                          <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">State<b>*</b></Form.Label>
                              <Form.Select
                              aria-label="Default select example"
                              defaultValue = {formData.custState}
                              onChange={(e) => onStateChange(e)}
                              name = 'custState'
                              isInvalid={!!errors.custState}
                              //onClick={(e) => {onStateChange(e)}}
                              //defaultChecked = '1'
                            >
                              {/* <option value="">Select State</option> */}
                              <option value="Tamil Nadu">Tamil Nadu</option>
                              {/* <option value="lowest_price">Lowest Price</option>
                              <option value="highest_price">Highest Price</option>
                              <option value="today_special">Today's special</option> */}
                            </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {errors.custState}
                              </Form.Control.Feedback>
                            </Form.Group>
                            
                          </div>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                          <div className="form-location inputbt-space">

                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">City<b>*</b></Form.Label>
                              <Form.Select
                               name="custCity"
                               value = {formData.custCity}
                               onChange={(e) => onCityChange(e)}
                               isInvalid={!!errors.custCity}
                              >
                                <option value=''>Select City</option>
                                {getCityLists()}
                              {/* <option value="lowest_price">Lowest Price</option>
                              <option value="highest_price">Highest Price</option>
                              <option value="today_special">Today's special</option> */}
                            </Form.Select>
                             {/*  <Form.Label className="label-text">
                                City
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="enter city"
                                name="custCity"
                                value={formData.custCity}
                                onChange={handleChange}
                                isInvalid={!!errors.custCity}
                              /> */}
                              <Form.Control.Feedback type="invalid">
                                {errors.custCity}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>
                        
                        <Col lg={12} md={12} xs={12}>
                          <div className="form-location inputbt-space">

                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">
                                Pincode<b>*</b>
                              </Form.Label>
                              <Form.Select
                                name="custPincode"
                                value = {formData.custPincode}
                                onChange={(e) => onPinCodeChange(e)}
                                isInvalid={!!errors.custPincode}
                              >
                                <option value=''>Select pincode</option>
                                {getPinCodeLists()}
                              </Form.Select>
                              {/* {formErrors.pinCode != '' && formErrors.pinCode != undefined && (<p type="invalid">{formErrors.pinCode}</p>)} */}
                             { <Form.Control.Feedback type="invalid">
                                {errors.custPincode}
                              </Form.Control.Feedback>}
                            </Form.Group>
                          </div>
                        </Col>

                        <Col lg={12} md={12} xs={12}>
                          <div className="add-button mt-2">
                            <Button
                              variant="primary"
                              to="/Home"
                              className="btn-lg btn-cancel"
                              type="submit"
                              onClick={() => handleCloseModal()}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="primary"
                              className="btn-lg btn-submit btn-size"
                              type="submit"
                              onClick={(selectedAddress === null)? addAddress:updateAddress}
                            >
                              {selectedAddress ? 'update' : 'submit'}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Modal.Body>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      <Footer />
      <ModalWindow data={modalData} onCloseModel={onCloseModel}/>
    </div>

  );
};

export default ShippingAddress;
