import React, { useEffect, useState, Suspense } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import { getCartCount, getCartInsert, getTodaySpecial, postCartUpdate, userValidate } from "../Services/serviceApi";
import { useCookies } from 'react-cookie';
// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import InputGroup from "react-bootstrap/InputGroup";
import SkeletonLoader from "../Components/skeleton-loader";
import ToastMessage from "../Components/toast-message";

const TodaySpecial = () => {

  const [cookies] = useCookies(['SESSIONINFO'], ['LOGGEDINFO']);
  const [cartCount, setCartCount] = useState(0);
  const [specialProducts, setSpecialProducts] = useState([]);
  //const userId = localStorage.getItem('user_id');
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState('')
  const [emptyData, setEmptyData] = useState({ status: 1 });
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [cartMsg, setCartMsg] = useState('');
  const [userData, setUserData] = useState('');

  const userId = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? cookies.LOGGEDINFO : cookies.SESSIONINFO;
  const userType = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? '' : 'guest';

  useEffect(() => {
    fetchData();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  const fetchData = async () => {
    getTodaySpecial(userId, userType, page, '')
      .then(response => {
        if (response.status == 1 && response.todayspecial.length > 0) {
          localStorage.setItem('totalpage', response.total_page)
          setTotalCount(response.todayspecial[0].tot_prod_cnt)
          setPage(page + 1);
          setEmptyData(response);
          setIsLoading(false)
          setSpecialProducts(response.todayspecial);
        }
        if (response.status === 2) {
          setEmptyData(response);
          setIsLoading(false)
        }
      })
  };

  const handleScroll = () => {
    const totalPage = localStorage.getItem('totalpage')
    const distanceToBottom = document.body.offsetHeight - (window.innerHeight + window.scrollY);
    const loadThreshold = 450;
    if (distanceToBottom < loadThreshold && !isFetching && page<=totalPage) {
      setIsFetching(true)
    }
  };

  useEffect(() => {
    if (isFetching) {
      fetchMoreListItems();
    }
  }, [isFetching]);


  const fetchMoreListItems = async () => {
    const totalPage = Number(localStorage.getItem('totalpage'));
    if (page <= totalPage) {
      await fetchMoreData();
    }
    setIsFetching(false);
  };


  const fetchMoreData = async () => {
    setTimeout(() => {
      
    setIsFetching(true)
    getTodaySpecial(userId, userType, page, '')
      .then(response => {
        if (response.status == 1 && response.todayspecial.length > 0) {
          localStorage.setItem('totalpage', response.total_page)
          setTotalCount(response.todayspecial[0].tot_prod_cnt)
          setEmptyData(response);
          setIsLoading(false)
          setPage(page + 1);
          setSpecialProducts(() => {
            return [...specialProducts, ...response.todayspecial];
          });
        }
        if (response.status === 2) {
          setIsFetching(false);
          setEmptyData(response);
          setIsLoading(false)
        }
        setIsFetching(false)
      })

    },);

  };

  useEffect(() => {
    updateQuantityChange()
  }, [])

  const addToCart = (productId) => {
    getCartInsert(userType, userId, productId)
      .then(response => {
        setUserData(response)
        setCartMsg(response.data.message)
        setShow(true)
        updateQuantityChange(productId, '');
      })
  };

  const onSortProducts = (e) => {
    if (e.target.value != '') {
      getTodaySpecial(userId, userType, page, e.target.value)
        .then(response => {
          if (response.status == 1 && response.todayspecial.length > 0) {
            setSpecialProducts(() => {
              return [...specialProducts, ...response.todayspecial];
            });
          }
        })
    }
  }

  const updateQuantityChange = (productId, action) => {
    const updatedProducts = specialProducts.map((product) => {
      if (product.product_id === productId) {
        let currentQuantity = parseInt(product.cart_qty) || 0;
        let updatedQuantity;

        if (action === 'increment') {
          updatedQuantity = currentQuantity + 1;
          setShow(true)
          setCartMsg('Success! Quantity added to your cart. Keep on shopping!')
        } else if (action === 'decrement' && currentQuantity > 0) {
          updatedQuantity = currentQuantity - 1;
          if (updatedQuantity == 0) {
            setShow(true)
            setCartMsg('Product successfully removed from the cart.')
          } else {
            setShow(true)
            setCartMsg('Quantity reduced successfully. Your cart is updated.')
          }
        } else if (action == '') {
          updatedQuantity = 1;
          setShow(true)
        } else {
          return product;
        }
        postCartUpdate(userId, productId, updatedQuantity, userType)
          .then(response => {
            setUserData(response)
            // setCartCount(response)
            getCartCounts()
          })
        return {
          ...product,
          cart_qty: updatedQuantity
        };
      }
      return product;
    });
    setSpecialProducts(updatedProducts);
  };

  const getCartCounts = () => {
    getCartCount(userId, userType)
      .then(response => {
        setCartCount(response)
      })
  }

  return (
    <div>
      <Header cartCount={cartCount} userData={userData} />
      <section className="product-detail mt-space pt-space">
        {/* <div className="border-line-top"></div> */}
        {(!isLoading && specialProducts.length === 0) ?
          <section className="">
            <div className="no-record text-center para-text">
              <img
                className="zoom-effect"
                src="../assets/images/no-record.svg"
                alt="Cart Image" title="Empty Cart"
              />
              <p>There is no available Products</p>
            </div>
          </section> : <div className="product-item">
            <Container>
              <div className="breadcrumb">
                <ul>
                  <li>
                    <Link to='/'>
                      Home
                      <span className="iconify">
                        <Icon icon="iconamoon:arrow-right-2-duotone" />
                      </span>
                    </Link>
                  </li>
                  <li className="active">Today Special</li>
                </ul>
              </div>
              <Row className="d-flex align-items-center">
                <Col lg={6} md={6} xs={5}>
                  <div className="item-list">
                    <p>{totalCount} Products Found</p>
                  </div>
                </Col>
                {/* <Col lg={6} md={7} xs={7}>
                <div className="price-list text-end">
                  <div className="price-option">
                    <span>Sort by:</span>
                    <Form.Select
                      aria-label="Default select   example"
                      onChange={(e) => { onSortProducts(e) }}
                    >
                      <option value="default">Default</option>
                      <option value="lowest_price">Lowest Price</option>
                      <option value="highest_price">Highest Price</option>
                      <option value="new_arrival">New Arrival</option>
                      <option value="most_order">Most Order</option>
                    </Form.Select>
                  </div>
                </div>
              </Col> */}
              </Row>
            </Container>
          </div>}
        {(isLoading) ? <SkeletonLoader />
        /* (isLoading)?<div className="loader-box">
            <div class="lds-ripple">
              <div className="loader-text">
                  <p>Loading...</p>
              </div>
          </div>
          </div>: */: <div className="product-list">
            <Container>
              <Row>
                {specialProducts.map((product, index) => (
                  <Suspense key={index}
                    fallback={
                      <img src={''} alt="Avatar" style={{ width: "50%" }} />
                    }
                  >
                    <Col lg={3} md={4} xs={6} key={index}>
                      <div className="product-box detail-box">
                        <Link to={`/product-detail/${product.product_slug}`} state={{ product }}>
                          <div>
                            <div className={`product-img`}>
                              <img
                                className="zoom-effect"
                                src={product.product_image}
                                alt={product.product_name}
                              />
                              <div className="product-icon">
                                <img src="../assets/images/special-icon.svg" alt="" />
                              </div>

                              {parseInt(product.selling_price) !== 0 && product.selling_price != null ?
                                (<div className="product-offer-box offer-orange">
                                  <span>
                                    <strong>{Math.round((product.actual_price - product.selling_price) / product.actual_price * 100)}%</strong> <br />
                                    <span className="off-text">OFF</span>
                                  </span>
                                </div>) : ('')}
                            </div>
                          </div>
                          <div className="product-title">
                            <h4>{product.product_name.charAt(0).toUpperCase() + product.product_name.slice(1)} / {product.product_name2} - {product.variant_name + ' ' + product.nic_name} </h4>
                          </div>
                        </Link>
                        <div className="select-card">
                          <Row className="d-flex align-items-center">
                            <Col lg={6} md={6} xs={12}>
                              <div className="price-list">
                                <h5>
                                  {<span className="price-tag">₹{(parseInt(product.selling_price) !== 0 && product.selling_price != null) ? product.selling_price : product.actual_price}</span>}
                                  {(parseInt(product.selling_price) !== 0 && product.selling_price != null) && <><span className="price-low">₹{product.actual_price}</span> <br /> You
                                    save ₹ {product.actual_price - product.selling_price}</>}
                                </h5>
                              </div>
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                              {product.cart_qty === null || product.cart_qty === 0 ? (
                                <div
                                  className="cart-btn"
                                >
                                  {product.stock_availability == 0 ? (<p className="out_of_stock">Out of Stock</p>) : (
                                    <Link to="javascript:;" onClick={() => addToCart(product.product_id)}>
                                      <span className="iconify">
                                        <Icon icon="solar:cart-4-linear" />
                                      </span>
                                      <span className="cart-hide">Add to Cart</span>
                                    </Link>
                                  )}
                                </div>) : (
                                <div className="price-add">
                                  {((product.stock_availability == 0) ?
                                    <p className="out_of_stock">Out of Stock</p> :
                                    (product.cart_qty !== null || product.cart_qty !== 0) ? (
                                      <div className="quantity-price mt-0">
                                        <Button className="qty-button" onClick={() => updateQuantityChange(product.product_id, 'decrement')}>
                                          <span className="iconify">
                                            <Icon icon="ic:baseline-minus" />
                                          </span>
                                        </Button>
                                        <InputGroup className="qty-count">
                                          <Form.Control
                                            placeholder="1"
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            value={product.cart_qty}
                                            onChange={(e) => updateQuantityChange(product.product_id, e.target.value)}

                                          />
                                        </InputGroup>
                                        <Button className="qty-button" onClick={() => updateQuantityChange(product.product_id, 'increment')}>
                                          <span className="iconify">
                                            <Icon icon="ic:baseline-plus" />
                                          </span>
                                        </Button>
                                      </div>
                                    ) : ('')
                                  )}
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Suspense>
                ))}
                {/* loader */}
                {/* <Col lg={3} md={6} xs={5}>
                 <div className="product-box detail-box">
                  <Link to="/ProductDetail">
                    <div className="product-img skeleton-loader">
      
                      <div className="product-icon skeleton-loader">
                      
                      </div>
                      <div className="product-offer-box offer-orange skeleton-loader">
                      
                      </div>
                    </div>
                    <div className="product-title skeleton-loader">
                     
                    </div>
                  </Link>
                  <div className="select-card">
                    <Row className="d-flex align-items-center">
                      <Col lg={6} md={6} xs={12}>
                        <div className="price-list skeleton-loader">
                          
                        </div>
                      </Col>
                      <Col lg={6} md={6} xs={12}>
                        <div className="cart-btn skeleton-loader">
                          
                        </div>
                      </Col>
                    </Row>
                  </div>
              </div>
              </Col> */}
                {/* loader */}
              </Row>
            </Container>
            {/* <div className="view-btn">
            <Link className="">
              VIEW More
            </Link>
          </div> */}
          </div>}
      </section>
      <ToastMessage show={show} setShow={setShow} cartMsg={cartMsg} />
      <Footer />
    </div>
  );
};

export default TodaySpecial;
